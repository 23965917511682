<!--=====================================
    Shopping Car
    ======================================-->

<div class="ps-section--shopping ps-shopping-cart">

  <div class="container">

    <div class="ps-section__header">

      <h1>Mi pedido</h1>

    </div>

    <div class="ps-section__content">

      <div class="table-responsive cart-list">

        <table class="table ps-table--shopping-cart">

          <thead>

            <tr>
              <th class="text-center">Producto</th>
              <th class="text-center">Precio</th>
              <th class="text-center">Cantidad</th>
              <th class="text-center">Presentacion</th>
              <th class="text-center">Total</th>
              <th class="text-center"></th>


            </tr>

          </thead>

          <tbody>
            <tr *ngIf="items.length  == 0">
              <td colspan="4" class="text-center"> No cuentas con productos en tu carro </td>
            </tr>
            <tr *ngFor="let product of items">
              <td class="text-center">

                <div class="ps-product--cart">

                  <div class="ps-product__thumbnail">

                    <a [routerLink]="['/product/',product.id]"><img src="{{product.img}}" alt=""></a>

                  </div>
                  <div class="ps-product__content">

                    <a [routerLink]="['/product/',product.id]"> {{product.name |uppercase}}</a>


                  </div>
                </div>

              </td>
              <td class="price text-center">$ {{product.price | number:'1.0'}}</td>
              <td class="text-center">

                <div class="form-group--number">

                  <button class="up" (click)="quantityUp(product)">+</button>

                  <button class="down" (click)="quantityDown(product)">-</button>

                  <input class="form-control" type="number" min="1" (change)="change(product,quantity.value)" #quantity
                    value="{{ product.quantity}}">

                </div>

              </td>
              <td class="text-center">
                {{product.description |uppercase}}
              </td>
              <td class="text-center">$ {{product.quantity * product.price | number:'1.0'}}</td>

              <td class="text-center">

                <a (click)="remove(product)">
                  <i class="icon-cross"></i>
                </a>

              </td>

            </tr>



          </tbody>

        </table>

      </div>

      <hr>


    </div>

  </div>

</div>
