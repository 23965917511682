<!--=====================================
    				Products found
    				======================================-->

<div class="ps-shopping ps-tab-root" *ngIf="products">

  <!--=====================================
      Shoping Header
      ======================================-->

  <div class="ps-shopping__header">

    <p><strong> {{totalFound}}</strong> Products encontrados</p>

    <div class="ps-shopping__actions">





    </div>

  </div>

  <!--=====================================
      Shoping Body
      ======================================-->

  <div class="ps-tabs">

    <!--=====================================
        Grid View
        ======================================-->

    <div class="ps-tab active" id="tab-1">

      <div class="ps-shopping-product">

        <div class="row">

          <!--=====================================
              Product
              ======================================-->

          <div *ngFor="let product of products" class="col-lg-3 col-md-5 col-7">

            <div class="ps-product">

              <div class="ps-product__thumbnail">

                <a [routerLink]="['/product/',product.id]">
                  <img src="{{product.imageUrl}}" alt="">
                </a>

                <ul class="ps-product__actions">

                  <li>
                    <a style="cursor: pointer;" (click)="addCart(product)" data-toggle="tooltip" data-placement="top"
                      title="Agregar al Carro">
                      <i class="icon-bag2"></i>
                    </a>
                  </li>

                  <li>
                    <a [routerLink]="['/product/',product.id]" data-toggle="tooltip"
                      data-placement="top" title="Quick View">
                      <i class="icon-eye"></i>
                    </a>
                  </li>
                  <li>
                    <a style="cursor: pointer;" (click)="addwishList(product.id)" data-toggle="tooltip"
                      data-placement="top" title="Agregar Favoritos">
                      <i class="icon-heart"></i>
                    </a>
                  </li>



                </ul>

              </div>

              <div class="ps-product__container">

                <a class="ps-product__vendor" href="#">{{product.Brandname}}</a>

                <div class="ps-product__content">

                  <a class="ps-product__title" [routerLink]="['/product/',product.id]">
                    {{product.name}} </a>
                  <p class="ps-product__desc">{{product.presentationName}}</p>
                  <p class="ps-product__price">$ {{product.price | number :'1.0' }}
                  </p>

                </div>

                <div class="ps-product__content hover">

                  <a class="ps-product__title" [routerLink]="['/product/',product.id]"
                   >{{product.name}}</a>
                  <p class="ps-product__desc">{{product.presentationName}}</p>
                  <p class="ps-product__price">$ {{product.price | number :'1.0' }}</p>

                </div>

              </div>

            </div>

          </div><!-- End Product -->




        </div>

      </div>

      <div class="ps-pagination">

        <ul class="pagination">
          <li *ngIf="(pages >= page) && (page !> 1)"><a (click)="previousPage()"><i class="icon-chevron-left"></i></a>
          </li>
          <li *ngIf="pages != page"><a (click)="nextPage()"><i class="icon-chevron-right"></i></a></li>
        </ul>

      </div>

    </div><!-- End Grid View-->
  </div>

</div>

<section *ngIf="!products" class="d-flex justify-content-center">
  <div class="spinner-border text-muted my-3"></div>
</section>
