<!--=====================================
	Footer
	======================================-->

<footer class="ps-footer" *ngIf="identity">

  <div class="container">





    <!--=====================================
  CopyRight - Payment method Footer
  ======================================-->

    <div class="ps-footer__copyright">

      <p>© 2020 MayoristasApp. Todos los derechos reservados</p>

      

    </div>

  </div>

</footer>
