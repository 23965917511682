import { Component, OnInit } from '@angular/core';
import { IItem } from 'src/app/interface/item';
import { CartService } from '../../../services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit {
  public items: Array<IItem> = [];
  public totalPrice = 0;
  public totalQuantity = 0;
  constructor(private cartService: CartService) {}

  ngOnInit() {
    this.cartService.currentDataCart$.subscribe((x) => {
      if (x) {
        this.items = x;
        this.totalQuantity = x.length;
        this.totalPrice = x.reduce(
          (sum, current) => sum + current.price * current.quantity,
          0
        );
      }
    });
  }

  remove(producto: IItem) {
    this.cartService.removeElementCart(producto);
  }
}
