<!--=====================================
    Breadcrumb
    ======================================-->

<div class="ps-breadcrumb">

  <div class="container">

    <ul class="breadcrumb">

      <li><a [routerLink]="['/']">Inicio</a></li>

      <li>Hola {{identity.fullName}}</li>

    </ul>

    <a style="cursor: pointer;" (click)="logout()" class="float-right">Cerrar sesión</a>

  </div>

</div>
