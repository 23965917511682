import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-breadcrumb',
  templateUrl: './account-breadcrumb.component.html',
  styleUrls: ['./account-breadcrumb.component.css'],
})
export class AccountBreadcrumbComponent implements OnInit {
  public identity;
  constructor(private generalService: GeneralService, private router: Router) {}

  ngOnInit() {
    this.identity = this.generalService.getIdentity();
  }

  logout() {
    localStorage.removeItem('identity');
    localStorage.removeItem('token');
    localStorage.removeItem('menu');
    localStorage.removeItem('list');
    localStorage.removeItem('color');
    setTimeout(function () {
      window.location.reload();
    }, 1);
    //this.router.navigate(['/login']);
  }
}
