import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';
import { WishListService } from '../../../services/wishList.service';
import { CartService } from '../../../services/cart.service';
import { IItem } from '../../../interface/item';

@Component({
  selector: 'app-product-left',
  templateUrl: './product-left.component.html',
  styleUrls: ['./product-left.component.css'],
})
export class ProductLeftComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public token;
  public product;
  public imgUrl;
  public cantidad = 1;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location,
    private wishListService: WishListService,
    private cartService: CartService
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
    this.identity = this._generalService.getIdentity();
  }

  ngOnInit() {
    this._route.params.subscribe((params) => {
      let param = params['id'];
      this.getProducts(param);
    });
  }

  addCart(data, unit) {
    const product: IItem = {
      id: data.id,
      name: data.name,
      img: data.imageUrl,
      price: data.pirceWihtTax,
      quantity: unit,
      description: data.productPresentation.name,
      quantityMin: Number(data.quantityMin),
      quantityStep: Number(data.quantityStep),
    };

    this.cartService.changeCart(product);
    this._location.back();
  }

  getProducts(id) {
    let object = {
      url: 'Product/GetProductIncludeById?id=' + id,
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        if (response) {
          this.product = response;
          this.product.quantityMin =
            this.product.quantityMin > 0 ? this.product.quantityMin : 1;
          this.product.quantityStep =
            this.product.quantityStep > 0 ? this.product.quantityStep : 1;
          this.cantidad = this.product.quantityMin;
          this.imgUrl = this.product.imageUrl;
        }
      },
      (error) => {}
    );
  }

  quantityUp() {
    this.cantidad += this.product.quantityStep;
  }

  quantityDown() {
    if (this.cantidad > this.product.quantityMin) {
      this.cantidad -= this.product.quantityStep;
    }
  }

  changeValueInput(value) {
    if (this.product.quantityMin > 1 && this.product.quantityStep > 1) {
      if (
        value % this.product.quantityStep === 0 &&
        value > this.product.quantityMin
      ) {
        this.cantidad = Number(value);
        return;
      }
      this.cantidad = this.product.quantityMin;
      return;
    }
    this.cantidad = value;
  }

  addwishList(data) {
    let object = {
      userId: this.identity.userId,
      productId: data,
    };
    this.wishListService.addWishList(object);
  }
}
