import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';
import { CartService } from '../../../services/cart.service';
import Swal from 'sweetalert2';
import { SearchBreadcrumbComponent } from '../../search/search-breadcrumb/search-breadcrumb.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public categorias;
  public menus;
  public family;
  public token;

  public cantFavoritos = 0;

  public imgDefault;

  public seacrh;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location,
    private cartService: CartService
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();


  }

  ngOnInit() {
    this.identity = this._generalService.getIdentity();
    this.menus = this._generalService.getMenu();
    this.imgDefault =
      'https://ui-avatars.com/api/?size=512&background=' +
      this.colorHEX() +
      '&color=fff&name=' +
      this.identity.fullName;

    this.getTotalWishList();
  }

  generarLetra() {
    var letras = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
    ];
    var numero = (Math.random() * 15).toFixed(0);
    return letras[numero];
  }

  colorHEX() {
    var coolor = '';
    for (var i = 0; i < 6; i++) {
      coolor = coolor + this.generarLetra();
    }
    localStorage.setItem('color', coolor);
    return coolor;
  }

  getTotalWishList() {
    let data = {
      pagina: 1,
      cantidadAMostrar: 1,
      paginable: true,
      isSearch: true,
      searchData: this.identity.userId,
    };
    let object = {
      data: data,
      url: 'WishList/AllDataByUser',
      token: this.token,
    };
    this._generalService.getObjectParams(object).subscribe(
      (response) => {
        console.log(response);
        if (response && response.data) {
          this.cantFavoritos = response.totalData;
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
        }
      }
    );
  }

  ngDoCheck() {
    this.identity = this._generalService.getIdentity();
  }

  goSearch(value: string) {
    if (value.length === 0) {
      return;
    }
    this._router.navigate(['/search'], {
      queryParams: { search: value, page: 1 },
    });
  }
}
