import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home-promotions',
  templateUrl: './home-promotions.component.html',
  styleUrls: ['./home-promotions.component.css'],
})
export class HomePromotionsComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public promotions;
  public token;

  public preload: boolean = false;

  public render: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    this.preload = true;
    this.identity = this._generalService.getIdentity();
    this.getPromotion();
    this.preload = false;
  }

  callBack() {
    if (this.render) {
      this.render = false;
    }
  }

  getPromotion() {
    let object = {
      url: 'DefaultBanner/GetDefaultBanner',
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          this.promotions = response;
        }
      },
      (error) => {}
    );
  }
}
