import { ProductsBreadcrumbComponent } from './components/products/products-breadcrumb/products-breadcrumb.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderPromotionComponent } from './components/shared/header-promotion/header-promotion.component';
import { NewletterComponent } from './components/shared/newletter/newletter.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/product/product.component';
import { SearchComponent } from './components/search/search.component';
import { HomeBannerComponent } from './components/home/home-banner/home-banner.component';
import { HomeFeaturesComponent } from './components/home/home-features/home-features.component';
import { HomePromotionsComponent } from './components/home/home-promotions/home-promotions.component';
import { ProductsShowcaseComponent } from './components/products/products-showcase/products-showcase.component';
import { SearchBreadcrumbComponent } from './components/search/search-breadcrumb/search-breadcrumb.component';
import { SearchShowcaseComponent } from './components/search/search-showcase/search-showcase.component';
import { CallToActionComponent } from './components/product/call-to-action/call-to-action.component';
import { ProductBreadcrumbComponent } from './components/product/product-breadcrumb/product-breadcrumb.component';
import { ProductLeftComponent } from './components/product/product-left/product-left.component';
import { ProductRightComponent } from './components/product/product-right/product-right.component';
import { SimilarBoughtComponent } from './components/product/similar-bought/similar-bought.component';
import { RelatedProductComponent } from './components/product/related-product/related-product.component';
import { AccountComponent } from './components/account/account.component';
import { AccountBreadcrumbComponent } from './components/account/account-breadcrumb/account-breadcrumb.component';
import { AccountProfileComponent } from './components/account/account-profile/account-profile.component';
import { AccountWishlistComponent } from './components/account/account-profile/account-wishlist/account-wishlist.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from './components/login/recover-password/recover-password.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { CartBreadcrumbComponent } from './components/shopping-cart/cart-breadcrumb/cart-breadcrumb.component';
import { CartToActionComponent } from './components/shopping-cart/cart-to-action/cart-to-action.component';
import { CartShowcaseComponent } from './components/shopping-cart/cart-showcase/cart-showcase.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CartComponent } from './components/shared/cart/cart.component';
import { HeaderMobileComponent } from './components/shared/header-mobile/header-mobile.component';
import { AccountShoppingComponent } from './components/account/account-profile/account-shopping/account-shopping.component';
import { AccountStoreComponent } from './components/account/account-profile/account-store/account-store.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    AppComponent,
    HeaderPromotionComponent,
    HeaderComponent,
    NewletterComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
    ProductsComponent,
    ProductComponent,
    SearchComponent,
    HomeBannerComponent,
    HomeFeaturesComponent,
    HomePromotionsComponent,
    ProductsBreadcrumbComponent,
    ProductsShowcaseComponent,
    SearchBreadcrumbComponent,
    SearchShowcaseComponent,
    CallToActionComponent,
    ProductBreadcrumbComponent,
    ProductLeftComponent,
    ProductRightComponent,
    SimilarBoughtComponent,
    RelatedProductComponent,
    AccountComponent,
    AccountBreadcrumbComponent,
    AccountProfileComponent,
    AccountWishlistComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    ShoppingCartComponent,
    CartBreadcrumbComponent,
    CartToActionComponent,
    CartShowcaseComponent,
    CheckoutComponent,
    CartComponent,
    HeaderMobileComponent,
    AccountShoppingComponent,
    AccountStoreComponent
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule,InfiniteScrollModule],
  providers: [NgxImageCompressService],
  bootstrap: [AppComponent],
})
export class AppModule {}
