<div class="ps-product--detail ps-product--fullwidth">

  <!--=====================================
      Product Header
      ======================================-->

  <div class="ps-product__header">


    <!--=====================================
        Gallery
        ======================================-->

    <div class="ps-product__thumbnail" data-vertical="true">

      <figure>

        <div class="ps-wrapper">

          <div class="ps-product__gallery" data-arrow="true">

            <div class="item">
              <a href="{{imgUrl}}">
                <img src="{{imgUrl}}" alt="">
              </a>
            </div>


          </div>

        </div>

      </figure>


    </div><!-- End Gallery -->

    <!--=====================================
        Product Info
        ======================================-->

    <div class="ps-product__info" *ngIf="product">

      <h1>{{product.name}}</h1>

      <div class="ps-product__meta">

        <p>Marca: <a>{{product.brand.name}}</a></p>
        <p>Presentacion: <a>{{product.productPresentation.name}}</a></p>
      </div>



      <h4 class="ps-product__price">$ {{product.pirceWihtTax | number:'1.0'}}</h4>

      <div class="ps-product__desc">

        <p>

          Vendido por:<a class="mr-20" href="shop-default.html"><strong>
              {{identity.companyName}}</strong></a>

          Estado:<a href="shop-default.html"><strong class="ps-tag--in-stock"> In stock</strong></a>

        </p>


      </div>

      <div class="ps-product__shopping">

        <figure>

          <figcaption>Cantidad</figcaption>

          <div class="form-group--number">

            <button class="up" (click)="quantityUp()">
              <i class="fa fa-plus"></i>
            </button>

            <button class="down" (click)="quantityDown()">
              <i class="fa fa-minus"></i>
            </button>

            <input class="form-control" type="number" name="cantidad" #quantity
              (change)="changeValueInput(quantity.value)" [(ngModel)]="cantidad">

          </div>

        </figure>

        <a class="ps-btn ps-btn--black" (click)="addCart(product,cantidad)">Agregar al carro</a>
<!--
        <a class="ps-btn" href="#">Comprar</a> -->

        <div class="ps-product__actions"> 

          <a style="cursor: pointer;" (click)="addwishList(product.id)" data-toggle="tooltip" data-placement="top"
            title="Agregar Favoritos">
            <i class="icon-heart"></i>
          </a>

        </div>

      </div>

      <div class="ps-product__specification">

        <!-- <a class="report" href="#">Report Abuse</a> -->

        <p><strong>SKU:</strong>{{product.code}}</p>

        <p class="categories">

          <strong> Categorizacion:</strong>

          <a [routerLink]="['/search/',product.category.name.toLowerCase()]">
            {{product.category.name.toLowerCase() | titlecase}}</a>,
          <a [routerLink]="['/search/',product.family.name.toLowerCase()]">
            {{product.family.name.toLowerCase() | titlecase}}</a>,
          <a [routerLink]="['/search/',product.line.name.toLowerCase()]">
            {{product.line.name.toLowerCase() | titlecase}}</a>

        </p>

        <p class="tags"><strong> Palabra clave</strong>

          <a [routerLink]="['/search/',product.name2.toLowerCase()]">{{product.name2.toLowerCase() | titlecase}}</a>

        </p>

      </div>



    </div> <!-- End Product Info -->

  </div> <!-- End Product header -->

</div>
