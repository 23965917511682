<app-search-breadcrumb></app-search-breadcrumb>


<div class="container-fuid bg-white my-4">
  <div class="container">
    <div class="ps-layout--shop">
      <section>
        <app-search-showcase></app-search-showcase>
      </section>
    </div>
  </div>
</div>
