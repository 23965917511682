export class OrderDetail {
  constructor(
    public id: string,
    public orderId: string,
    public productId: string,
    public quantity: string,
    public unitValue: string,
    public orderPreview: string
  ) {}
}
