<!--=====================================
			Customers who bought
			======================================-->

<div class="ps-section--default ps-customer-bought">

  <div class="ps-section__header">

    <h3>Misma Familia</h3>

  </div>

  <div class="ps-section__content">

    <div class="row">

      <div class="col-lg-2 col-md-4 col-6 " *ngFor="let product of products">

        <div class="ps-product">

          <div class="ps-product__thumbnail">

            <a [routerLink]="['/product/',product.id]">
              <img src="{{product.imageUrl}}" alt="">
            </a>
            <!--
            <div class="ps-product__badge">-37%</div> -->

            <ul class="ps-product__actions">

              <li>
                <a style="cursor: pointer;" (click)="addCart(product)" data-toggle="tooltip" data-placement="top"
                  title="Agregar al Carro">
                  <i class="icon-bag2"></i>
                </a>
              </li>

              <li>
                <a [routerLink]="['/product/',product.id]" data-toggle="tooltip" data-placement="top"
                  title="Quick View">
                  <i class="icon-eye"></i>
                </a>
              </li>

              <li>
                <a style="cursor: pointer;" (click)="addwishList(product.id)" data-toggle="tooltip" data-placement="top"
                  title="Agregar Favoritos">
                  <i class="icon-heart"></i>
                </a>
              </li>

            </ul>

          </div>

          <div class="ps-product__container">

            <a class="ps-product__vendor" href="#">{{product.brandName}}</a>

            <div class="ps-product__content">

              <a class="ps-product__title" [routerLink]="['/product/',product.id]">{{product.name}}</a>



              <p class="ps-product__price sale">$ {{product.price | number:'1.0'}}</p>

            </div>

            <div class="ps-product__content hover">

              <a class="ps-product__title" [routerLink]="['/product/',product.id]">{{product.name}}</a>

              <p class="ps-product__price sale">$ {{product.price | number:'1.0'}}</p>

            </div>

          </div>
        </div>

      </div>



    </div>

  </div>

</div><!--  End Customers who bought -->
