import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';

@Component({
  selector: 'app-newletter',
  templateUrl: './newletter.component.html',
  styleUrls: ['./newletter.component.css'],
})
export class NewletterComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public username;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {}
  ngOnInit() {
    console.log(this._route);
    this.identity = this._generalService.getIdentity();
  }

  ngDoCheck() {
    this.identity = this._generalService.getIdentity();
  }
}
