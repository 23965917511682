import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';

@Component({
  selector: 'app-account-shopping',
  templateUrl: './account-shopping.component.html',
  styleUrls: ['./account-shopping.component.css'],
})
export class AccountShoppingComponent implements OnInit {
  public token;
  public identity;
  public data;
  public page = 1;
  public pages;
  public itemPerPage = 5;
  public busqueda;
  public orders = [];
  public order:any[] = [];
  public totalValue = 0;
  public orderId = 0;
  constructor(private generalService: GeneralService) {
    this.token = generalService.getToken();
    this.identity = generalService.getIdentity();
    this.busqueda = this.identity.userId;
    this.data = {
      pagina: this.page,
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.busqueda,
    };
  }

  ngOnInit() {
    this.getOrders(this.data);
  }

  getOrders(data) {
    let object = {
      data: data,
      url: 'Order/AllorderByUser',
      token: this.token,
    };
    this.generalService.getObjectParams(object).subscribe(
      (response) => {
        console.log(response);
        if (response && response.data) {
          this.orders = response.data;
          this.pages = response.pages;
          this.itemPerPage = data.cantidadAMostrar;
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
        }
      }
    );
  }

  getDetail(order) {
    this.orderId = order.number;
    this.order = order.orderDetails; // Asegúrate de que `orderDetails` sea un arreglo
    console.log('Order Details:', order.orderDetails);
    this.totalValue = order.total;
  }


  previousPage() {
    this.page--;
    let data = {
      pagina: this.page,
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.busqueda,
    };
    this.getOrders(data);
  }

  nextPage() {
    this.page++;
    let data = {
      pagina: this.page,
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.busqueda,
    };
    this.getOrders(data);
  }
}
