<table class="table ps-table--whishlist dt-responsive">

  <thead>

    <tr>

      <th>#</th>

      <th>Direccion envio</th>

      <th>Precio</th>

      <th>Estado</th>

      <th> Detalles</th>

    </tr>

  </thead>

  <tbody>

    <!-- Product -->

    <tr *ngFor="let order of orders">

      <td>
        <strong>{{order.number}}</strong>
      </td>

      <td>
        {{order.address}}

      </td>

      <td class="price text-center">$ {{order.total | number:'1.0'}}</td>

      <td class="text-center">
        <span class=" badge {{order.state === 1?'bg-success':order.state === 0?'bg-warning':'bg-danger'}}">
          {{order.state === 1 ? 'Confirmado': order.state === 0 ? 'Pendiente': 'Cancelado'}}</span>

      </td>

      <td>
        <a class="info p-0" title="Ver pedido" data-toggle="modal" (click)="getDetail(order)"
          data-target="#viewOrder" href="#">
          <i class="fas fa-receipt fa-2x mr-100"></i>
        </a>

      </td>

    </tr>



  </tbody>

</table>

<div class="ps-pagination" *ngIf="orders.length > 0">

  <ul class="pagination">
    <li *ngIf="(pages >= page) && (page !> 1)"><a (click)="previousPage()"><i class="icon-chevron-left"></i></a>
    </li>
    <li *ngIf="pages != page"><a (click)="nextPage()"><i class="icon-chevron-right"></i></a></li>
  </ul>

</div>

<!-- Modal -->
<!-- Actualizar Docuemtnto -->
<div class="modal fade text-left" id="viewOrder" tabindex="-1" role="dialog" aria-labelledby="myModalLabel17"
  aria-hidden="true">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel17">Detalle Pedido # {{orderId}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="ps-cart__content">
          <div class=" ps-cart__items cart-list">
            <div class="ps-product--cart-mobile" *ngFor="let item of order">
              <div class="ps-product__thumbnail">
                <a [routerLink]="['/product/',item.productId]" data-dismiss="modal">
                  <img src="{{item.imageUrl}}" alt="Product">
                </a>
              </div>

              <div class="ps-product__content">
                
                <a [routerLink]="['/product/',item.productId]"
                  data-dismiss="modal">{{item.productName | uppercase}}</a>
               
                <br />
                <small class="ps-product text-left p-0 m-0">
                  Cantidad:
                  <span>{{item.quantity +' '+ item.productPresentationName }}</span>
                  x
                  <span>$ {{item.unitValue | number:'1.0'}}</span>

                </small>
              </div>

              <br />
              <br />
              <br />
            </div>

          </div>

          <div class="ps-cart__footer">

            <h4 class="text-right">Total:<strong>$ {{totalValue | number:'1.0'}}</strong></h4>
            <figure>
              <!-- <a class="ps-btn" [routerLink]="['/shopping-cart/']">Ver Carro</a> -->
              <!-- <a class="ps-btn" href="checkout.html">Checkout</a> -->
            </figure>

          </div>

        </div>


      </div>
    </div>
  </div>
</div>
<!-- Modal -->
