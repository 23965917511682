import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';

@Component({
  selector: 'app-products-breadcrumb',
  templateUrl: './products-breadcrumb.component.html',
  styleUrls: ['./products-breadcrumb.component.css'],
})
export class ProductsBreadcrumbComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public token;

  public param;

  public preload: boolean = false;

  public render: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    this._route.queryParamMap.subscribe((params) => {
      let seacrh = params.get('search');
      this.param = seacrh;
    });
  }
}
