<section class="my-overlay" *ngIf="sendOrder">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</section>



<!--=====================================
    Breadcrumb
    ======================================-->

<div class="ps-breadcrumb">

  <div class="container">

    <ul class="breadcrumb">

      <li><a [routerLink]="['/']">Inicio</a></li>

      <li><a [routerLink]="['/shopping-cart']">Mi Pedido</a></li>

      <li>Checkout</li>

    </ul>

  </div>

</div>


<!--=====================================
    Checkout
    ======================================-->
<div class="ps-checkout ps-section--shopping">

  <div class="container">

    <div class="ps-section__header">

      <h1>Checkout</h1>

    </div>

    <div class="ps-section__content">

      <form class="ps-form--checkout" action="do_action" method="post">

        <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-12">
            <div class="ps-form__billing-info">


              <h3 class="ps-form__heading">Direcciones</h3>



              <table>

                <thead>

                  <th>
                    Direccion
                  </th>

                </thead>
                <tbody>

                  <tr *ngFor="let dir of address">

                    <td>
                      <input (click)="changeAddres(dir)" type="radio" id="checkDir" name="chekDir">
                    </td>
                    <td>
                      <p>Sede: <strong>{{dir.name}}</strong></p>
                      <a>{{dir.addres}}</a>
                      <p>{{dir.phone}}</p>
                    </td>

                  </tr>

                </tbody>

              </table>
            </div>
          </div>
          <div class="col-xl-5 col-lg-4 col-sm-12">

            <div class="ps-form__total">

              <h3 class="ps-form__heading">Mi pedido</h3>

              <div class="content">

                <div class="ps-block--checkout-total">

                  <div class="ps-block__header d-flex justify-content-between">

                    <p>Product</p>

                    <p>Total</p>

                  </div>

                  <div class="ps-block__content">

                    <table class="table ps-block__products">

                      <tbody>

                        <tr *ngFor="let item of items">

                          <td>
                            <a [routerLink]="['/product/',item.id]">{{item.name}}</a>
                            <p> {{item.quantity}} X <strong>{{item.description}}</strong></p>
                          </td>

                          <td class="text-right">${{item.quantity * item.price | number:'1.0'}}</td>

                        </tr>


                      </tbody>

                    </table>
                  </div>
                  <br />

                  <h3 class="ps-form__heading">Notas</h3>
                  <textarea class="form-control" rows="2" [(ngModel)]="comment" name="comment">
                  </textarea>
                  <h3 class="text-right">Total <span>$ {{totalPrice | number:'1.0'}}</span></h3>
                </div>




                <section *ngIf='!sendOrder'>
                  <a class="ps-btn ps-btn--fullwidth" (click)="setPedido()" [attr.disabled]='false'>Realizar pedido</a>
                </section>

                <section *ngIf='sendOrder'>
                  <a class="ps-btn ps-btn--fullwidth" style="pointer-events: none;">Enviando...</a>
                </section>
              </div>

            </div>

          </div>

        </div>

      </form>

    </div>

  </div>

</div>
