import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';
import { CartService } from '../../../services/cart.service';
import { IItem } from '../../../interface/item';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.css'],
})
export class CallToActionComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public token;

  public products;

  public imgUrl;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location,
    private cartService: CartService
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    this.getAllData();
  }

  getAllData() {
    this._route.params.subscribe((params) => {
      let param = params['id'];
      this.getProducts(param);
    });
  }

  getProducts(id) {
    let object = {
      url: 'Product/GetProductById?id=' + id,
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        if (response) {
          this.products = response;
          this.imgUrl = 'data:image/jpeg;base64,' + this.products.foto;
        }
      },
      (error) => {}
    );
  }

  addCart(data, unit) {
    const product: IItem = {
      id: data.id,
      name: data.name,
      img: data.foto,
      price: data.pirceWihtTax,
      quantity: unit,
      description: data.productPresentation.name,
      quantityMin: Number(data.quantityMin),
      quantityStep: Number(data.quantityStep),
    };

    this.cartService.changeCart(product);
  }
}
