import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { GeneralService } from '../../../services/general.service';
import { ResetPassword } from '../../../models/resetPassword';
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css'],
})
export class RecoverPasswordComponent implements OnInit {
  public username;
  public pass;
  public pass1;
  public expires;

  public identity;

  public newPassword: ResetPassword;

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private _generalService: GeneralService
  ) {
    this.newPassword = new ResetPassword('', '', '');
  }

  ngOnInit() {
    this.identity = this._generalService.getIdentity();
    this.activeRouter.queryParamMap.subscribe((params) => {
      this.expires = params.get('expire');
      this.username = params.get('username');
      console.log(this.username);
      if (!this.username && !this.expires) {
        this.router.navigate(['/login']);
      }
      if (this.identity) {
        Swal.fire({
          title: 'Uy!',
          icon: 'error',
          text: 'Ya iniciaste sesion, por facor cierrala para continuar',
        }).then(() => {
          this.router.navigate(['/']);
        });
      }
    });
  }

  onSubmit(form) {
    if (form.invalid) {
      return;
    }
    this.newPassword.email = atob(this.username);
    this.newPassword.expire = this.expires;
    this.newPassword.newPassword = this.pass;
    let object = {
      data: this.newPassword,
      url: 'Authentication/ResetPassword',
    };

    // loguear al usaurio y conseguir sus datos}
    this._generalService.postObjectFree(object).subscribe(
      (response) => {
        console.log(response);
        if (!response) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: 'Algo salio mal..',
          });
        } else {
          Swal.fire({
            title: 'Felicidades',
            icon: 'success',
            text: 'Se restauro su contraseña, Inice sesion nuevamente',
          }).then((result) => {
            if (result) {
              this.router.navigate(['/login']);
            }
          });
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.error,
          }).then((result) => {
            if (result) {
              this.router.navigate(['/login']);
            }
          });
        }
      }
    );
  }

  validatePassword(password) {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/;
    if (!pattern.test(password.value)) {
      password.parentNode.classList.add('was-validated');

      console.warn(password);
      password.value = '';
    }
  }

  validateSimil(password) {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/;
    if (!pattern.test(password.value) || this.pass !== password.value) {
      password.parentNode.classList.add('was-validated');
      // $(password).parent().addClass('was-validated')

      password.value = '';
    }
  }
}
