<!--=====================================
    	Home Features
    	======================================-->

<div class="ps-site-features">

  <div class="container">

    <div class="ps-block--site-features ps-block--site-features-2">

      <div class="ps-block__item">
        <div class="ps-block__left"><i class="icon-rocket"></i></div>
        <div class="ps-block__right">
          <h4>Free Delivery</h4>
          <p>For all oders over $99</p>
        </div>
      </div>

      <div class="ps-block__item">
        <div class="ps-block__left"><i class="icon-sync"></i></div>
        <div class="ps-block__right">
          <h4>90 Days Return</h4>
          <p>If goods have problems</p>
        </div>
      </div>

      <div class="ps-block__item">
        <div class="ps-block__left"><i class="icon-credit-card"></i></div>
        <div class="ps-block__right">
          <h4>Secure Payment</h4>
          <p>100% secure payment</p>
        </div>
      </div>

      <div class="ps-block__item">
        <div class="ps-block__left"><i class="icon-bubbles"></i></div>
        <div class="ps-block__right">
          <h4>24/7 Support</h4>
          <p>Dedicated support</p>
        </div>
      </div>

    </div>

  </div>

</div><!-- End Home Features-->
