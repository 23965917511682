import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserLogin } from '../../../models/userLogin';
import { GeneralService } from '../../../services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  username;
  rememberMe: boolean = false;

  public title: string;
  public identity;
  public token;
  constructor(
    // tslint:disable-next-line:variable-name
    private _router: Router,
    // tslint:disable-next-line:variable-name
    private _generalService: GeneralService
  ) {
    this.title = 'Identificate';
    this.identity = this._generalService.getIdentity();
  }

  ngOnInit() {
    document.body.classList.add('blank-page');
    if (this.identity) {
      this._router.navigate(['/']);
    }
    console.log('componente de login cargado...');
  }

  onSubmit() {
    let object = {
      url:
        'Authentication/sendPasswordResetEmail?emailAddress=' +
        this.username +
        '&siteUrl=' +
        window.location.origin,
    };

    // loguear al usaurio y conseguir sus datos}
    this._generalService.postObjectFree(object).subscribe(
      (response) => {
        if (!response) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: 'Algo salio mal..',
          });
        } else {
          Swal.fire({
            title: 'Felicidades',
            icon: 'success',
            text: 'Se ah enviado un correo',
          }).then((result) => {
            setTimeout(function () {
              window.location.reload();
            }, 1);

            this._router.navigate(['/login']);
          });
        }
      },
      (error) => {
        const errorMessage = error as any;
        if (errorMessage != null) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.error,
          });
        }
      }
    );
  }
}
