<!-- <app-call-to-action></app-call-to-action> -->
<app-product-breadcrumb></app-product-breadcrumb>


<div class="ps-page--product">

  <div class="ps-container">

    <!--=====================================
  Product Container
  ======================================-->

    <div class="ps-page__container">
      <!--=====================================
    			Left Column
    			======================================-->

      <div class="ps-page__left">

        <app-product-left></app-product-left>
      </div>
      <!--=====================================
    			Right Column
    			======================================-->

      <div class="ps-page__right">
        <app-product-right></app-product-right>
      </div>
    </div>

    <app-similar-bought></app-similar-bought>
    <!-- <app-related-product></app-related-product> -->
  </div>


</div>
