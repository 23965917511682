<!--=====================================
          Cart
          ======================================-->

<div class="ps-cart--mini">

  <a class="header__extra" [routerLink]="['/shopping-cart/']">
    <i class="icon-bag2"></i><span><i>{{totalQuantity}}</i></span>
  </a>

  <div class="ps-cart__content" *ngIf="items.length > 0">


    <div class=" ps-cart__items cart-list">

      <div class="ps-product--cart-mobile" *ngFor="let item of items">

        <div class="ps-product__thumbnail">
          <a [routerLink]="['/product/',item.id]">
            <img src="{{item.img}}">
          </a>
        </div>

        <div class="ps-product__content">
          <!-- <a class="ps-product__remove" (click)="remove(item)">
            <i class="icon-cross"></i>
          </a> -->
          <a [routerLink]="['/product/',item.id]">{{item.name | uppercase}}</a>
          <!-- <p><strong>Tienda</strong>{{identity.companyName | titlecase}}</p> -->
          <br />
          <small class="ps-product text-left p-0 m-0">
            Cantidad:
            <span>{{item.quantity}} </span>
            <span>{{item.description}}</span>
            X
            <span>{{item.price | number:'1.0'}}</span>

          </small>
        </div>

      </div>


    </div>

    <div class="ps-cart__footer">

      <h3>Total:<strong>$ {{totalPrice | number:'1.0'}}</strong></h3>
      <figure>
        <a class="ps-btn" [routerLink]="['/shopping-cart/']">Ver Carro</a>
        <a class="ps-btn" [routerLink]="['/checkout/']">Finalizar Pedido</a>
      </figure>

    </div>

  </div>

</div>