import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { GLOBAL } from '../../services/global';
import { Location } from '@angular/common';
import { OwlCarouselConfig } from '../../functions';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public token;

  public category;
  public family;

  public preload: boolean = false;

  public render: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
   
  }
}
