<!--=====================================
    	Home Banner
    	======================================-->
<br />
<div class="ps-home-banner" *ngIf="!preload && sliders.length> 0">
  <div class="ps-carousel--nav-inside owl-slider" data-owl-auto="true" data-owl-loop="false" data-owl-speed="5000"
    data-owl-gap="0" data-owl-nav="true" data-owl-dots="true" data-owl-item="1" data-owl-item-xs="1"
    data-owl-item-sm="1" data-owl-item-md="1" data-owl-item-lg="1" data-owl-duration="1000" data-owl-mousedrag="on"
    data-owl-animate-in="fadeIn" data-owl-animate-out="fadeOut">


    <div class="ps-banner--market-4" *ngFor="let slide of sliders;let lastIndex=last">
      <img src="data:image/jpeg;base64,{{slide.tagImg}}" alt="">
      <div class="ps-banner__content">
        <h4>{{slide.tagH4}}</h4>
        <h3>{{slide.tagH3_1}} <br />
          {{slide.tagH3_2}} <br />
          <p>{{slide.tagH3_3}}<strong> {{slide.tagH3_4s}}</strong></p>
        </h3>
        <a class="ps-btn" [routerLink]="['/product/',slide.productId]">{{slide.tagButton}}</a>
      </div>
      {{lastIndex ? callBack():""}}
    </div>
  </div>

</div><!-- End Home Banner-->

<div *ngIf="sliders.length  == 0" class="d-flex justify-content-center">
  <div class="spinner-border text-muted my-5"></div>
</div>
