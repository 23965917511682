import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { UserGuard } from './services/user.guard';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/product/product.component';
import { SearchComponent } from './components/search/search.component';
import { AccountComponent } from './components/account/account.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from './components/login/recover-password/recover-password.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'recover-password', component: RecoverPasswordComponent },
  { path: '', component: AccountComponent, canActivate: [UserGuard] },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'product/:id',
    component: ProductComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'shopping-cart',
    component: ShoppingCartComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [UserGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
