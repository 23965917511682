import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-store',
  templateUrl: './account-store.component.html',
  styleUrls: ['./account-store.component.css'],
})
export class AccountStoreComponent implements OnInit {
  public stores;
  public identity;
  constructor() {
    this.stores = JSON.parse(localStorage.getItem('fullIdentity'));
    this.identity = JSON.parse(localStorage.getItem('identity'));
  }

  ngOnInit() {}

  setData(value) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'va a cambiar de tienda a ' + value.companyName + '!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si,cambiar!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Felicidades!', 'Se cambio correctamente.', 'success').then(
          (result) => {
            // persistir datos de usuario
            localStorage.setItem('identity', JSON.stringify(value));

            // consegur el token
            localStorage.setItem('token', value.token);

            localStorage.setItem('menu', JSON.stringify(value.Menu));
            setTimeout(function () {
              window.location.reload();
            }, 1);
          }
        );
      }
    });
  }
}
