import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { IItem } from '../interface/item';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cart = new BehaviorSubject<Array<IItem>>(null);
  public currentDataCart$ = this.cart.asObservable();
  constructor(private location: Location) {
    this.cart.next(JSON.parse(localStorage.getItem('list')));
  }

  public changeCart(newData: IItem) {
    /*obtenemos el valor almacenado enel local storage para persistencia de datos*/
    let listCart = JSON.parse(localStorage.getItem('list'));
    // si no es el primer item*/
    if (listCart) {
      /*buscamos si existe ya el item;*/
      let objIndex = listCart.findIndex((obj) => obj.id === newData.id);
      /*si ya cargamos el producto aumentamos su cantidad*/
      if (objIndex !== -1) {
        listCart[objIndex].quantity += newData.quantity;
      } else {
        /*si es la primera vez de item lo agregamos al carrito*/
        listCart.push(newData);
      }
    } else {
      /*si es la primera vez inicializamos*/
      listCart = [];
      listCart.push(newData);
    }
    this.cart.next(listCart);
    localStorage.setItem('list', JSON.stringify(listCart));
    Swal.fire({
      title: 'Felicidades',
      icon: 'success',
      text: 'Se ha actualizado el carrito',
    });
  }

  public updateCart(newData: IItem) {
    /*obtenemos el valor almacenado enel local storage para persistencia de datos*/
    let listCart = JSON.parse(localStorage.getItem('list'));
    // si no es el primer item*/
    if (listCart) {
      /*buscamos si existe ya el item;*/
      let objIndex = listCart.findIndex((obj) => obj.id === newData.id);
      /*si ya cargamos el producto aumentamos su cantidad*/
      if (objIndex !== -1) {
        listCart[objIndex].quantity = newData.quantity;
      } else {
        /*si es la primera vez de item lo agregamos al carrito*/
        listCart.push(newData);
      }
    } else {
      /*si es la primera vez inicializamos*/
      listCart = [];
      listCart.push(newData);
    }
    this.cart.next(listCart);
    localStorage.setItem('list', JSON.stringify(listCart));
    Swal.fire({
      title: 'Felicidades',
      icon: 'success',
      text: 'Se ha actualizado el carrito',
    });
  }

  public removeElementCart(newData: IItem) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'No podra revertir esta accion!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo!',
    }).then((result) => {
      /*obtenemos el carrito*/
      let listCart = JSON.parse(localStorage.getItem('list'));
      /*buscamos el item a eliminar*/
      let objIndex = listCart.findIndex((obj) => obj.id === newData.id);

      if (objIndex !== -1) {
        /*Seteamos la cantidad en 1 (ya que los array se modifican los valores por referencia,*/
        /*si vovlemos a agregarlo la cantidad no se reiniciará)*/
        listCart[objIndex].quantity = 1;
        /*Eliminamos el item del array del carrito*/
        listCart.splice(objIndex, 1);
      }
      this.cart.next(listCart);
      localStorage.setItem('list', JSON.stringify(listCart));
    });
  }
}
