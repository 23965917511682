<!--=====================================
	Header
	======================================-->

<header class="header header--standard header--market-place-4" data-sticky="true" *ngIf="identity">



  <!--=====================================
  Header Content
  ======================================-->

  <div class="header__content">

    <div class="container">

      <div class="header__content-left">

        <!--=====================================
        Logo
        ======================================-->

        <a class="ps-logo"  >
          <img src="{{identity.companyLogo ? identity.companyLogo : 'assets/img/template/logo_light.png'}}"
            width="{{identity.companyLogo? 80:200}}" alt="">
        </a>

        <!--=====================================
        Menú
        ======================================-->

        <div class="menu--product-categories">

          <div class="menu__toggle">
            <i class="icon-menu"></i>

          </div>

          <div class="menu__content">
            <ul class="menu--dropdown">
              <ng-container *ngFor="let parent of menus">
                <li class=" menu-item-has-children has-mega-menu" *ngIf="parent.codPadre === '00000000-0000-0000-0000-000000000000'">
                  <a [routerLink]="['/products']" [queryParams]="{search:parent.nombreMenu.toLowerCase(),page:1}">
                    {{parent.nombreMenu}}</a>
                  <div class="mega-menu">
                    <!-- <ng-container *ngFor="let child of menus"> -->
                    <!-- <div class="mega-menu__column" *ngIf="parent.codmenu === child.codPadre"> -->
                    <!-- <h4>{{family.familyName}}<span class="sub-toggle"></span></h4> -->
                    <ul class="mega-menu__list">
                      <ng-container *ngFor="let child of menus">
                        <li *ngIf="child.codPadre == parent.codmenu"><a [routerLink]="['products/']"
                            [queryParams]="{search:child.nombreMenu.toLowerCase(),page:1}">{{child.nombreMenu}}</a>
                        </li>
                      </ng-container>
                    </ul>
                    <!-- </div> -->
                    <!-- </ng-container> -->
                  </div>
                </li>
              </ng-container>
            </ul>

          </div>

        </div><!-- End menu-->

      </div><!-- End Header Content Left-->

      <!--=====================================
      Search
      ======================================-->

      <div class="header__content-center">
        <form class="ps-form--quick-search">
          <input (keyup.enter)="goSearch(search.value)" #search class="form-control" type="text"
            placeholder="Estoy comprando ..." name="search" [(ngModel)]="seacrh">
          <button (click)="goSearch(search.value)">Buscar..</button>
        </form>
      </div>

      <div class="header__content-right">

        <div class="header__actions">

          <!--=====================================
          Wishlist
          ======================================-->

          <a class="header__extra" [routerLink]="['/account/']" [queryParams]="{page:'wish'}">
            <i class="icon-heart"></i><span><i id="inputWish">{{cantFavoritos}}</i></span>
          </a>

          <app-cart></app-cart>
          <!--=====================================
          Login and Register
          ======================================-->
          <div class="ps-block--user-header">
            <div class="ps-block__left">
              <div *ngIf="!identity.userFoto" class="ps-block__left text-center">
                <a [routerLink]="['/account/']">
                  <img src="{{imgDefault}}" class="img-fluid rounded-circle ml-auto" style="width: 40px;">
                </a>
              </div>
              <div *ngIf="identity.userFoto" class="ps-block__left text-center">
                <a [routerLink]="['/account/']">
                  <img src="{{identity.userFoto}}" class="img-fluid rounded-circle ml-auto" style="width: 40px;">
                </a>
              </div>
            </div>
            <div class="ps-block__right">
              <a [routerLink]="['/account/']">Mi Cuenta</a>
            </div>
          </div>

        </div><!-- End Header Actions-->

      </div><!-- End Header Content Right-->

    </div><!-- End Container-->

  </div><!-- End Header Content-->

</header>
