<!--=====================================
	Header Mobile
	======================================-->

<header class="header header--mobile" data-sticky="true" *ngIf="identity">

  <div class="navigation--mobile">

    <div class="navigation__left">

      <!--=====================================
    Menu Mobile
    ======================================-->

      <div class="menu--product-categories">

        <div class="ps-shop__filter-mb mt-4" (click)="filterSidebar();" >
          <i class="icon-menu "></i>
        </div>

        <div class="ps-filter--sidebar">

          <div class="ps-filter__header">
            <h3>Categorias</h3><a class="ps-btn--close ps-btn--no-boder" (click) ="closeFilterSidebar();"></a>
          </div>

          <div class="ps-filter__content">

            <aside class="widget widget_shop">

              <ul class="ps-list--categories">
                <ng-container *ngFor="let parenMenu of menus">
                  <li class="current-menu-item menu-item-has-children" *ngIf="parenMenu.codPadre === '00000000-0000-0000-0000-000000000000'"><a
                      [routerLink]="['/products']" [queryParams]="{search:parenMenu.nombreMenu.toLowerCase(),page:1}"
                      (click)="selectMenu()">{{parenMenu.nombreMenu}}</a><span class="sub-toggle" (click)="activateToggle()"><i
                        class="fa fa-angle-down"></i></span>
                    <ul class="sub-menu" style="display: none;">
                      <ng-container *ngFor="let childMenu of menus">
                        <li class="current-menu-item" *ngIf="parenMenu.codmenu==childMenu.codPadre"><a
                            [routerLink]="['/products']"
                            [queryParams]="{search:childMenu.nombreMenu.toLowerCase(),page:1}"
                            (click)="selectMenu()">{{childMenu.nombreMenu}}</a>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ul>

            </aside>

          </div>

        </div>

      </div><!-- End menu-->

      <a class="ps-logo pl-3 pl-sm-5">
        <img src="{{identity.companyLogo}}" width="50" alt="">
      </a>

    </div>

    <div class="navigation__right">

      <div class="header__actions">

        <!--=====================================
          Cart
          ======================================-->
        <app-cart></app-cart>
        <!--=====================================
      Login and Register
      ======================================-->
        <div class="ps-block--user-header">
          <div class="ps-block__left">
            <div *ngIf="!identity.userFoto" class="ps-block__left text-center">
              <a [routerLink]="['/account/']">
                <img src="{{imgDefault}}" class="img-fluid rounded-circle ml-auto" style="width: 40px;">
              </a>
            </div>
            <div *ngIf="identity.userFoto" class="ps-block__left text-center">
              <a [routerLink]="['/account/']">
                <img src="{{identity.userFoto}}" class="img-fluid rounded-circle ml-auto" style="width: 40px;">
              </a>
            </div>
          </div>
          <div class="ps-block__right">
            <a [routerLink]="['/account/']">Mi Cuenta</a>
          </div>
        </div>

      </div>

    </div>

  </div>

  <!--=====================================
Search
======================================-->

  <!-- <div class="ps-search--mobile">

    <form class="ps-form--search-mobile">
      <div class="form-group--nest">

        <input (keyup.enter)="goSearch(search.value)" #search class="form-control" type="text"
          placeholder="Estoy comprando ...">
        <button (click)="goSearch(search.value)"><i class="icon-magnifier"></i></button>

      </div>
    </form>

  </div> -->

  <div class="header__content-center">
    <form class="ps-form--quick-search">
      <input (keyup.enter)="goSearch(search.value)" #search class="form-control" type="text"
        placeholder="Estoy comprando ..." name="search">
      <button (click)="goSearch(search.value)">Buscar..</button>
    </form>
  </div>



</header> <!-- End Header Mobile -->
