<div class="ps-promotions" *ngIf="!preload">

  <div class="container">

    <div class="row">
      <ng-container *ngFor="let promotion of promotions">
        <div class="col-md-6 col-12 " *ngIf="promotion != null && promotion.state">
          <a class="ps-collection" [routerLink]="['/product/',promotion.productId]">
            <img src="data:image/jpeg;base64,{{promotion.image}}" alt="">
          </a>
        </div>
      </ng-container>
    </div>

  </div>

</div><!-- End Home Promotions-->
<div *ngIf="preload" class="d-flex justify-content-center">
  <div class="spinner-border text-muted my-5"></div>
</div>
