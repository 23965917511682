import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { GeneralService } from './general.service';
import { WishList } from '../models/wishList';

@Injectable({
  providedIn: 'root',
})
export class WishListService {
  public token;
  public WishList: WishList;
  constructor(private _generalService: GeneralService) {
    this.token = _generalService.getToken();
    this.WishList = new WishList('', '');
  }

  addWishList(data) {
    this.WishList.userId = data.userId;
    this.WishList.productId = data.productId;
    let object = {
      data: this.WishList,
      url: 'WishList/CreateData',
      token: this.token,
    };
    this._generalService.postObject(object).subscribe(
      (response) => {
        console.log(response);

        if (response) {
          let obj = document.getElementById('inputWish');
          let cant = Number(obj.innerHTML) + 1;
          obj.innerHTML = cant.toString();
          console.warn(cant);
          Swal.fire({
            title: 'Felicidades',
            icon: 'success',
            text: 'Se ah agregado a favoritos',
          });
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
          Swal.fire({
            title: 'Advertencia',
            icon: 'warning',
            text: 'ya se encuentra agregado a favoritos',
          });
        }
      }
    );
  }
}
