import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserLogin } from '../../models/userLogin';
import { GeneralService } from '../../services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [GeneralService],
})
export class LoginComponent implements OnInit {
  user: UserLogin;
  rememberMe: boolean = false;

  public title: string;
  public identity;
  public token;
  constructor(
    // tslint:disable-next-line:variable-name
    private _router: Router,
    // tslint:disable-next-line:variable-name
    private _generalService: GeneralService
  ) {
    this.title = 'Identificate';
    this.identity = this._generalService.getIdentity();
    this.user = new UserLogin('', '', '2');
  }

  ngOnInit() {
    this.validateRemember();
    document.body.classList.add('blank-page');
    if (this.identity) {
      this._router.navigate(['/']);
    }
    console.log('componente de login cargado...');
  }

  validateRemember() {
    /*=============================================
		Validar acción de recordar credencial de correo
		=============================================*/

    if (
      localStorage.getItem('rememberMe') &&
      localStorage.getItem('rememberMe') === 'yes'
    ) {
      this.user.username = localStorage.getItem('username');
      this.rememberMe = true;
    }
  }

  setRemember() {
    if (this.rememberMe) {
      localStorage.setItem('rememberMe', 'yes');
    } else {
      localStorage.setItem('rememberMe', 'no');
    }
  }

  onSubmit() {
    console.log(this.user);
    // loguear al usaurio y conseguir sus datos}
    this._generalService.getAuthentication(this.user).subscribe(
      (response) => {
        this.identity = response;

        console.log(this.identity);

        if (!this.identity || !this.identity[0].userId) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: 'Algo salio mal..',
          });
        } else {

          localStorage.setItem('fullIdentity', JSON.stringify(this.identity));

          // persistir datos de usuario
          localStorage.setItem('identity', JSON.stringify(this.identity[0]));

          // consegur el token
          localStorage.setItem('token', this.identity[0].token);

          //username
          localStorage.setItem('username', this.user.username);

          localStorage.setItem('menu', JSON.stringify(this.identity[0].Menu));

          this.setRemember();
          setTimeout(function () {
            window.location.reload();
          }, 1);

          this._router.navigate(['/']);
        }
      },
      (error) => {
        const errorMessage = error as any;
        if (errorMessage != null) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: 'Algo salio mal..',
          });
        }
      }
    );
  }
}
