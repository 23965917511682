import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';
import { WishListService } from '../../../services/wishList.service';
import { CartService } from '../../../services/cart.service';
import Swal from 'sweetalert2';
import { IItem } from '../../../interface/item';

@Component({
  selector: 'app-similar-bought',
  templateUrl: './similar-bought.component.html',
  styleUrls: ['./similar-bought.component.css'],
})
export class SimilarBoughtComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public token;
  public family;
  public totalData;
  public products;
  public itemPerPage = 6;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location,
    private wishListService: WishListService,
    private cartService: CartService
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    this.identity = this._generalService.getIdentity();
    this.getAllData();
  }

  getAllData() {
    this._route.params.subscribe((params) => {
      let param = params['id'];
      this.getProduct(param);
    });
  }
  async addCart(data) {
    const { value: unit } = await Swal.fire({
      title: 'Ingrese cantidad',
      icon: 'info',
      input: 'range',
      inputAttributes: {
        min: data.quantityMin,
        max: '100',
        step: data.quantityStep,
      },
      inputValue: data.quantityMin,
    });
    if (unit) {
      const product: IItem = {
        id: data.id,
        name: data.name,
        img: data.imageUrl,
        price: data.price,
        quantity: Number(unit),
        quantityMin: Number(data.quantityMin),
        quantityStep: Number(data.quantityStep),
        description: data.presentationName,
      };

      this.cartService.changeCart(product);
    }
  }

  getProduct(id) {
    let object = {
      url: 'Product/GetProductIncludeById?id=' + id,
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        if (response) {
          this.family = response.family;
          this.getTotalData();
        }
      },
      (error) => {}
    );
  }

  getProducts() {
    let data = {
      pagina: this.getRandomPage(1, this.totalData / this.itemPerPage),
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.family.name,
    };
    let object = {
      data: data,
      url: 'Product/AllProductsShowCase',
      token: this.token,
    };
    this._generalService.getObjectParams(object).subscribe(
      (response) => {
        if (response && response.data) {
          this.products = response.data;
        }
      },
      (error) => {}
    );
  }

  getRandomPage(minValue, maxValue) {
    let min = minValue;
    let max = maxValue;
    let random = Math.floor(Math.random() * (+max + 1 - +min)) + +min;
    return random;
  }

  getTotalData() {
    let data = {
      pagina: 1,
      cantidadAMostrar: 1,
      paginable: true,
      isSearch: true,
      searchData: this.family.name,
    };
    let object = {
      data: data,
      url: 'Product/AllDataByFamily',
      token: this.token,
    };
    this._generalService.getObjectParams(object).subscribe(
      (response) => {
        if (response && response.data) {
          this.totalData = response.totalData;
          this.getProducts();
        }
      },
      (error) => {}
    );
  }

  addwishList(data) {
    let object = {
      userId: this.identity.userId,
      productId: data,
    };
    this.wishListService.addWishList(object);
  }
}
