<!-- <aside class="widget widget_product widget_features">

                <p><i class="icon-network"></i> Shipping worldwide</p>

                <p><i class="icon-3d-rotate"></i> Free 7-day return if eligible, so easy</p>

                <p><i class="icon-receipt"></i> Supplier give bills for this product.</p>

                <p><i class="icon-credit-card"></i> Pay online or when receiving goods</p>

            </aside> -->

<!-- <aside class="widget widget_sell-on-site">

                <p><i class="icon-store"></i> Sell on MarketPlace?<a href="#"> Register Now !</a></p>

            </aside> -->

<aside class="widget widget_same-brand">

  <h3>Producto Similar</h3>

  <div class="widget__content">

    <div class="ps-product" *ngFor="let product of products">

      <div class="ps-product__thumbnail">

        <a [routerLink]="['/product/',product.id]">
          <img src="{{this.product.imageUrl}}" alt="">
        </a>
        <!--
        <div class="ps-product__badge">-37%</div> -->

        <ul class="ps-product__actions">

          <li>
            <a style="cursor: pointer;" (click)="addCart(product)" data-toggle="tooltip" data-placement="top"
              title="Agregar al Carro">
              <i class="icon-bag2"></i>
            </a>
          </li>

          <li>
            <a [routerLink]="['/product/',product.id]" data-toggle="tooltip" data-placement="top" title="Quick View">
              <i class="icon-eye"></i>
            </a>
          </li>

          <li>
            <a style="cursor: pointer;" (click)="addwishList(product.id)" data-toggle="tooltip" data-placement="top"
              title="Agregar Favoritos">

              <i class="icon-heart"></i>
            </a>
          </li>

        </ul>

      </div>

      <div class="ps-product__container">

        <a class="ps-product__vendor" href="#">{{product.brandName}}</a>

        <div class="ps-product__content">

          <a class="ps-product__title" [routerLink]="['/product/',product.id]">{{product.name}}</a>



          <p class="ps-product__price sale">$ {{product.price | number:'1.0'}}</p>

        </div>

        <div class="ps-product__content hover">

          <a class="ps-product__title" [routerLink]="['/product/',product.id]">{{product.name}}</a>

          <p class="ps-product__price sale">$ {{product.price | number:'1.0'}}</p>

        </div>

      </div>

    </div>



  </div>

</aside>
