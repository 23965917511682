import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../services/cart.service';
import Swal from 'sweetalert2';
import { IItem } from '../../../interface/item';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cart-showcase',
  templateUrl: './cart-showcase.component.html',
  styleUrls: ['./cart-showcase.component.css'],
})
export class CartShowcaseComponent implements OnInit {
  public items: Array<IItem> = [];
  public totalPrice = 0;
  public totalQuantity = 0;
  constructor(private cartService: CartService, private location: Location) {}

  ngOnInit() {
    this.cartService.currentDataCart$.subscribe((x) => {
      if (x) {
        this.items = x;
        this.totalQuantity = x.length;
        this.totalPrice = x.reduce(
          (sum, current) => sum + current.price * current.quantity,
          0
        );
      }
    });
  }

  quantityUp(producto: IItem) {
    producto.quantity += producto.quantityStep;

    this.cartService.updateCart(producto);
  }

  quantityDown(producto: IItem) {
    producto.quantity -= producto.quantityStep;
    this.cartService.updateCart(producto);
  }

  change(producto: IItem, quantity) {
    if (
      quantity % producto.quantityStep === 0 &&
      quantity > producto.quantityMin
    ) {
      producto.quantity = Number(quantity);
      this.cartService.updateCart(producto);
      return;
    }
    setTimeout(function () {
      window.location.reload();
    }, 1);
  }

  remove(producto: IItem) {
    this.cartService.removeElementCart(producto);
  }

  backPage() {
    this.location.back();
  }
}
