<!--=====================================
    				Products found
    				======================================-->

<div class="ps-shopping ps-tab-root" *ngIf="stores">

  <!--=====================================
                  Shoping Header
                  ======================================-->


  <!--=====================================
                  Shoping Body
                  ======================================-->

  <div class="ps-tabs">

    <!--=====================================
                    Grid View
                    ======================================-->

    <div class="ps-tab active" id="tab-1">

      <div class="ps-shopping-product">

        <div class="row">

          <!--=====================================
                          Product
                          ======================================-->

          <div *ngFor="let store of stores" class="col-lg-2 col-md-4 col-6">

            <div class="ps-product">

              <div class="ps-product__thumbnail">

                <a (click)="setData(store)">
                  <img src="{{store.companyLogo != null  ? store.companyLogo : 'assets/img/shop-2.jpg'}}" alt="">
                </a>



              </div>

              <div class="ps-product__container">

                <div class="ps-product__content">

                  <a class="ps-product__title" (click)="setData(store)">
                    {{store.companyName}}
                    <i class="fa fa-check-circle {{store.companyName === identity.companyName ? '':'d-none'}}"
                      aria-hidden="ture"></i>
                  </a>


                </div>

                <div class="ps-product__content hover">

                  <a class="ps-product__title" (click)="setData(store)">{{store.companyName}} <i
                      class="fa fa-check-circle {{store.companyName === identity.companyName ? '':'d-none'}}"
                      aria-hidden="ture"></i>
                  </a>


                </div>

              </div>

            </div>

          </div><!-- End Product -->




        </div>

      </div>

    </div><!-- End Grid View-->




  </div>

</div>

<section *ngIf="!stores" class="d-flex justify-content-center">
  <div class="spinner-border text-muted my-3"></div>
</section>
