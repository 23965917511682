import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';
import { OwlCarouselConfig } from '../../../functions';
@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css'],
})
export class HomeBannerComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public sliders: Array<any> = [];
  public token;

  public preload: boolean = false;

  public render: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    this.preload = true;
    this.identity = this._generalService.getIdentity();
    this.getSlider();
    this.preload = false;
  }

  callBack() {
    if (this.render) {
      this.render = false;
      OwlCarouselConfig.fnc();
    }
  }

  getSlider() {
    let object = {
      url: 'HorizontalSlider/GetHorizontalSlider',
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          let i;
          for (i in response) {
            if (response[i] != null) {
              this.sliders.push(response[i]);
            }
          }
        }
      },
      (error) => {}
    );
  }
}
