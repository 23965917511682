<header class="header header--product header--sticky" data-sticky="true">

  <nav class="navigation">

    <div class="container">

      <article class="ps-product--header-sticky">

        <div class="ps-product__thumbnail">

          <img src="{{imgUrl}}" alt="">

        </div>

        <div class="ps-product__wrapper">

          <div class="ps-product__content">

            <a class="ps-product__title" href="#">{{products.name}}</a>

          </div>

          <div class="ps-product__shopping"><span class="ps-product__price">

              <span>$ {{products.pirceWihtTax | number :'1.0'}}</span>

              <!-- <del>$125.17</del> -->
            </span>

            <a class="ps-btn"  (click)="addCart(products,1)"> Agregar al carrito</a>

          </div>

        </div>

      </article>

    </div>

  </nav>

</header>
