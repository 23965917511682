import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-promotion',
  templateUrl: './header-promotion.component.html',
  styleUrls: ['./header-promotion.component.css'],
})
export class HeaderPromotionComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public topbanner;
  public token;

  public preload: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    this.preload = true;
    this.getTopBanner();
    console.log(this._route);
    this.identity = this._generalService.getIdentity();
    this.preload = false;
  }

  getTopBanner() {
    let object = {
      url: 'TopBanner/GetTopBanner',
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          this.topbanner = response;
        } else {
        }
      },
      (error) => {
        console.log(<any>error);
      }
    );
  }

  ngDoCheck() {
    this.identity = this._generalService.getIdentity();
  }
}
