import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';


@Component({
  selector: 'app-related-product',
  templateUrl: './related-product.component.html',
  styleUrls: ['./related-product.component.css']
})
export class RelatedProductComponent implements OnInit {

  public title: string;
  public identity;
  public url: string;
  public token;
  public family;
  public totalData;
  public products;
  public itemPerPage = 4;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    this.getAllData();
  }

  getAllData() {
    this._route.params.subscribe((params) => {
      let param = params['id'];
      this.getProduct(param);
    });
  }

  getProduct(id) {
    let object = {
      url: 'Product/GetProductById?id=' + id,
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        if (response) {
          this.family = response.family;
          this.getTotalData();
        }
      },
      (error) => {}
    );
  }

  getProducts() {
    let data = {
      pagina: this.getRandomPage(1, this.totalData / this.itemPerPage),
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.family.name,
    };
    let object = {
      data: data,
      url: 'Product/AllDataByFamily',
      token: this.token,
    };
    this._generalService.getObjectParams(object).subscribe(
      (response) => {
        if (response && response.data) {
          this.products = response.data;
        }
      },
      (error) => {}
    );
  }

  getRandomPage(minValue, maxValue) {
    let min = minValue;
    let max = maxValue;
    let random = Math.floor(Math.random() * (+max + 1 - +min)) + +min;
    return random;
  }

  getTotalData() {
    let data = {
      pagina: 1,
      cantidadAMostrar: 1,
      paginable: true,
      isSearch: true,
      searchData: this.family.name,
    };
    let object = {
      data: data,
      url: 'Product/AllDataByFamily',
      token: this.token,
    };
    this._generalService.getObjectParams(object).subscribe(
      (response) => {
        if (response && response.data) {
          this.totalData = response.totalData;
          this.getProducts();
        }
      },
      (error) => {}
    );
  }

}
