import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public url: string;
  public identity;
  public token;
  public menu;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  getAuthentication(user: any): Observable<any> {
    const params = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'Authentication/Login', params, {
      headers,
    });
  }

  getIdentity() {
    let identity = JSON.parse(localStorage.getItem('identity'));

    if (identity !== 'undefined') {
      this.identity = identity;
    } else {
      this.identity = null;
    }

    return this.identity;
  }

  getToken() {
    let token = localStorage.getItem('token');

    if (token != 'undefined') {
      this.token = 'bearer ' + token;
    } else {
      this.token = null;
    }

    return this.token;
  }

  getMenu() {
    let menu = JSON.parse(localStorage.getItem('menu'));

    console.log('menu');

    if (menu != 'undefined') {
      this.menu = menu;
    } else {
      this.menu = null;
    }

    return this.menu;
  }

  getObjectParams(object): Observable<any> {
    let params = JSON.stringify(object.data);
    let api = object.url;
    let token = object.token;
    let headers = new HttpHeaders()
      .set('content-Type', 'application/json')
      .set('authorization', token);

    return this._http.post(this.url + api, params, { headers: headers });
  }

  getObject(object): Observable<any> {
    let api = object.url;
    let token = object.token;
    let headers = new HttpHeaders()
      .set('content-Type', 'application/json')
      .set('authorization', token);

    return this._http.get(this.url + api, { headers: headers });
  }

  postObject(object): Observable<any> {
    let params = JSON.stringify(object.data);
    let api = object.url;
    let token = object.token;
    let headers = new HttpHeaders()
      .set('content-Type', 'application/json')
      .set('authorization', token);

    return this._http.post(this.url + api, params, { headers: headers });
  }

  postObjectString(object): Observable<any> {
    let params = JSON.stringify(object.data);
    let api = object.url;
    let token = object.token;
    let headers = new HttpHeaders()
      .set('content-Type', 'application/json')
      .set('authorization', token);

    return this._http.post(this.url + api, params, { headers: headers,responseType:'text' });
  }

  uploadImage(object): Observable<any> {
    let params = object.data;
    let api = object.url;
    let token = object.token;
    let headers = new HttpHeaders()
      //.set("Content-Type", "multipart/form-data")
      .set('Accept', 'application/json')
      .set('authorization', token);

    return this._http.post(this.url + api, params, { headers });
  }

  putObject(object): Observable<any> {
    let params = JSON.stringify(object.data);
    let api = object.url;
    let token = object.token;
    let headers = new HttpHeaders()
      .set('content-Type', 'application/json')
      .set('authorization', token);

    return this._http.put(this.url + api, params, { headers: headers });
  }
  getObjectParamsFree(object): Observable<any> {
    let params = JSON.stringify(object.data);
    let api = object.url;

    let headers = new HttpHeaders().set('content-Type', 'application/json');

    return this._http.post(this.url + api, params, { headers: headers });
  }

  getObjectFree(object): Observable<any> {
    let api = object.url;

    let headers = new HttpHeaders().set('content-Type', 'application/json');

    return this._http.get(this.url + api, { headers: headers });
  }

  postObjectFree(object): Observable<any> {
    let params = JSON.stringify(object.data);
    let api = object.url;

    let headers = new HttpHeaders().set('content-Type', 'application/json');
    return this._http.post(this.url + api, params, { headers: headers });
  }
}
