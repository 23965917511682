import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { CartService } from '../../services/cart.service';
import { IItem } from '../../interface/item';
import { Order } from 'src/app/models/Order';
import { OrderDetail } from '../../models/OrderDetail';
import Swal from 'sweetalert2';
import { title } from 'process';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  public token;
  public identity;
  public address;
  public addressSelect;
  public items: Array<IItem>;
  public totalPrice = 0;
  public totalQuantity = 0;
  public order: Order;
  public orderDetail: Array<OrderDetail> = [];
  public sendOrder = false;
  public comment = '';
  constructor(
    private router: Router,
    private generalService: GeneralService,
    private cartService: CartService
  ) {
    this.token = generalService.getToken();
    this.identity = generalService.getIdentity();
  }

  ngOnInit() {
    this.validatecart();
    this.getAddress(this.identity.userId);
    this.cartService.currentDataCart$.subscribe((x) => {
      if (x) {
        this.items = x;
        this.totalQuantity = x.length;
        this.totalPrice = x.reduce(
          (sum, current) => sum + current.price * current.quantity,
          0
        );
      }
    });
    this.validateInventory();
  }
  getAddress(id) {
    const object = {
      url: 'Customer/GetClientesByUserId?Id=' + id,
      token: this.token,
    };
    this.generalService.getObject(object).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          this.address = response;
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
        }
      }
    );
  }

  validateInventory() {
   
    const request = this.items.map((i) => (
      {
        productId: i.id,
        quantity: i.quantity
      }
    ));
    const object = {
      data: request,
      url: 'Order/VerifyOrder',
      token: this.token,
    };
    this.generalService.postObject(object).subscribe(
      (response) => {
        console.log(response);
        let productsChange = [];
        response.forEach((r) => {
          this.items.forEach((i) => {
            if (i.id === r.productId && !r.isValid) {
              if (r.actualQuantity == 0) {
                this.cartService.removeElementCart(i);
              }
              i.quantity = r.actualQuantity;
              this.cartService.updateCart(i);
              productsChange.push(i);
            }

          });
        });
        if (productsChange.length > 0) {
          Swal.fire({
            icon: 'info',
            title: 'Cambios en productos',
            html: '<ul>' + productsChange.map(t => `<li>${t.name} Cantidad: ${t.quantity}</li>`).join('') + '</ul>',
            position: "top-end",
            showConfirmButton: false,
            timer: 1500

          });
        }
      
      },
      (error) => {
        console.error(error);
      }

    );


  }


  setPedido() {
    if (!this.addressSelect) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia.!',
        text: 'Debe seleccionar una direccion',
      });
      return;
    }
  
    const f = new Date();
    const fecha: string =
      f.getFullYear() + '/' + (f.getMonth() + 1) + '/' + f.getDate();
    this.items.forEach((value, index) => {
      const temp = new OrderDetail(
        '00000000-0000-0000-0000-000000000000',
        '00000000-0000-0000-0000-000000000000',
        value.id.toString(),
        value.quantity.toString(),
        value.price.toString(),
        index.toString()
      );
      this.orderDetail.push(temp);
    });
    this.order = {
      id: '00000000-0000-0000-0000-000000000000',
      dateDelivery: fecha,
      customerId: this.addressSelect.id,
      state: '0',
      comment: this.comment,
      userId: this.identity.userId,
      device: 'webapp-market',
      orderDetail: this.orderDetail,
      total: this.totalPrice.toString(),
    };

    this.sendOrder = true;

    const object = {
      data: this.order,
      url: 'Order/Createorder',
      token: this.token,
    };
    this.generalService.postObjectString(object).subscribe(
      (response) => {
        console.log(response)
        this.sendOrder = false;
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Pedido ' + response,
            text: 'Creado con éxito',
          }).then((result) => {
            this.sendOrder = false;
            localStorage.removeItem('list');
            setTimeout(() => {
              window.location.reload();
            }, 1);
            this.router.navigate(['/account/'], {
              queryParams: { page: 'order' },
            });
          });
        }
      },
      (error) => {
        this.sendOrder = false;
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
        }
      }
    );
  }

  validatecart() {
    if (localStorage.getItem('list')) {
      const list = JSON.parse(localStorage.getItem('list'));
      if (list.length === 0) {
        this.router.navigate(['/shopping-cart/']);
        return;
      }
    } else {
      this.router.navigate(['/shopping-cart/']);
      return;
    }
  }

  changeAddres(address) {
    this.addressSelect = address;
  }
}
