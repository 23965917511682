import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';
import { WishList } from '../../../../models/wishList';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-wishlist',
  templateUrl: './account-wishlist.component.html',
  styleUrls: ['./account-wishlist.component.css'],
})
export class AccountWishlistComponent implements OnInit {
  public wishLists = [];
  public token;
  public identity;
  public data;
  public page = 1;
  public pages;
  public itemPerPage = 5;
  public busqueda;

  public WishList: WishList;

  constructor(private generalService: GeneralService) {
    this.token = this.generalService.getToken();
    this.identity = this.generalService.getIdentity();
    this.busqueda = this.identity.userId;
    this.data = {
      pagina: this.page,
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.busqueda,
    };
    this.WishList = new WishList('', '');
  }

  ngOnInit() {
    this.getWishList(this.data);
  }

  getWishList(data) {
    let object = {
      data: data,
      url: 'WishList/AllDataByUser',
      token: this.token,
    };
    this.generalService.getObjectParams(object).subscribe(
      (response) => {
        console.log(response);
        if (response && response.data) {
          this.wishLists = response.data;
          this.pages = response.pages;
          this.itemPerPage = data.cantidadAMostrar;
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
        }
      }
    );
  }

  remove(data) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'No podra revertir esta accion!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo!',
    }).then((result) => {
      if (result.value) {
        this.removeWishList(data);
      }
    });
  }

  removeWishList(data) {
    this.WishList.userId = data.userId;
    this.WishList.productId = data.productId;
    console.log(this.WishList);
    let object = {
      data: this.WishList,
      url: 'WishList/DeleteDate',
      token: this.token,
    };
    this.generalService.putObject(object).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          Swal.fire({
            title: 'Exito',
            text: 'Se ah eliminado exitosamente.!',
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              setTimeout(function () {
                window.location.reload();
              }, 1);
            }
          });
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
        }
      }
    );
  }

  previousPage() {
    this.page--;
    let data = {
      pagina: this.page,
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.busqueda,
    };
    this.getWishList(data);
  }

  nextPage() {
    this.page++;
    let data = {
      pagina: this.page,
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.busqueda,
    };
    this.getWishList(data);
  }
}
