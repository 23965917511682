

  <!--=====================================
                  Wishlist
                  ======================================-->


    <table class="table ps-table--whishlist dt-responsive">

      <thead>

        <tr>

          <th>Nombre</th>

          <th>Precio</th>

          <th>Stock</th>

          <th></th>

          <th></th>

        </tr>

      </thead>

      <tbody>
        <tr *ngIf="wishLists.length  == 0">
          <td colspan="3" class="text-center"> No cuentas con productos favoritos </td>
        </tr>

        <!-- Product -->

        <tr *ngFor="let wishList of wishLists">

          <td>
            <div class="ps-product--cart">
              <div class="ps-product__thumbnail"><a [routerLink]="['/product/',wishList.product.id]"><img
                    src="{{wishList.product.imageUrl}}" alt=""></a></div>
              <div class="ps-product__content"><a
                  [routerLink]="['/product/',wishList.product.id]">{{wishList.product.name}}
                  ({{wishList.product.code}})</a></div>
            </div>
          </td>

          <td class="price">$ {{wishList.product.pirceWihtTax | number:'1.0'}}</td>

          <td><span class="ps-tag ps-tag--in-stock">In-stock</span></td>

          <td><a class="ps-btn" href="#">Agregar al Carro</a></td>

          <td><a (click)="remove(wishList)"><i class="icon-cross"></i></a></td>

        </tr>



      </tbody>

    </table>

    <div class="ps-pagination" *ngIf="wishLists.length > 0">

      <ul class="pagination">
        <li *ngIf="(pages >= page) && (page !> 1)"><a (click)="previousPage()"><i class="icon-chevron-left"></i></a>
        </li>
        <li *ngIf="pages != page"><a (click)="nextPage()"><i class="icon-chevron-right"></i></a></li>
      </ul>

    </div>



