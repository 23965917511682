import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../services/cart.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cart-to-action',
  templateUrl: './cart-to-action.component.html',
  styleUrls: ['./cart-to-action.component.css'],
})
export class CartToActionComponent implements OnInit {
  public carts = [];
  public totalItems = 0;
  public subTotalCart = 0;

  constructor(private cartService: CartService, private location: Location) {}

  ngOnInit() {
    this.validateCart();
  }

  validateCart() {
    this.cartService.currentDataCart$.subscribe((x) => {
      if (x) {
        this.carts = x;
        this.totalItems = x.length;
        this.subTotalCart = x.reduce(
          (sum, current) => sum + current.price * current.quantity,
          0
        );
      }
    });
  }
  backPage() {
    this.location.back();
  }
}
