import { Component, OnInit } from '@angular/core';
import { ResetPassword } from '../../models/resetPassword';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit {
  public username;
  public pass;
  public pass1;
  public expires;

  public identity;


  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private _generalService: GeneralService
  ) {

  }

  ngOnInit() {
    this.identity = this._generalService.getIdentity();

  }


}
