<!--=====================================
	Newletter
	======================================-->

<div class="ps-newsletter" *ngIf="identity">

  <div class="container">

    <form class="ps-form--newsletter" action="do_action" method="post">

      <div class="row">

        <div class="col-xl-5 col-12 ">
          <div class="ps-form__left">
            <h3>Newsletter</h3>
            <p>Subcribe to get information about products and coupons</p>
          </div>
        </div>

        <div class="col-xl-7 col-12 ">

          <div class="ps-form__right">

            <div class="form-group--nest">

              <input class="form-control" type="email" placeholder="Email address">
              <button class="ps-btn">Subscribe</button>

            </div>

          </div>

        </div>

      </div>

    </form>

  </div>

</div>
