<div class="ps-section1--shopping1 ps-shopping-cart1">

  <div class="container">
    <div class="ps-section__content">
      <div class="d-flex1 flex-row-reverse1">
        <div class="p-21">
          <h3>Total <span>$ {{subTotalCart | number:'1.0'}}</span></h3>
        </div>
      </div>

      <div class="ps-section__cart-actions1">

        <a class="ps-btn1" (click)="backPage()">
          <i class="icon-arrow-left"></i> Seguir comprando
        </a>

        <a class="ps-btn1" [routerLink]="['/checkout/']" *ngIf="carts.length >0">
          Finalizar pedido <i class="icon-arrow-right"></i>
        </a>

      </div>
    </div>
  </div>
</div>
