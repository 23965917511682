<!--=====================================
  Login - Register Content
  ======================================-->


<div class="ps-my-account">

  <div class="text-center pb-20 pt-20">
    <img src="assets/img/template/logo_light_2.png" alt="" class="img-fluid mt-5" width="300" height="230">
  </div>

  <div class="container br-active pt-10">

    <form #loginForm="ngForm" (ngSubmit)="onSubmit()" class="ps-form--account login-box" novalidate>



      <div class="ps-tabs" style="border-radius: 15px;">

        <!--=====================================
                  Login Form
                  ======================================-->

        <div class="ps-tab active" id="sign-in">


          <div class="ps-form__content">

            <h5>Ingresar a tu cuenta</h5>

            <div class="form-group">

              <input class="form-control" type="text" placeholder="correo electronico" [(ngModel)]="user.username"
                name="email" email required>

              <div class="valid-feedback">Valid.</div>
              <div *ngIf="loginForm.submitted && loginForm.controls['email'].errors" class="invalid-feedback">Porfavor
                llene este campo.
              </div>

            </div>

            <div class="form-group">

              <input class="form-control" type="password" placeholder="Contraseña" [(ngModel)]="user.password"
                name="password" required>

              <div class="valid-feedback">Valid.</div>
              <div *ngIf="loginForm.submitted && loginForm.controls['password'].errors" class="invalid-feedback">
                Porfavot llene este
                campo.</div>




            </div>

            <div class="form-group form-forgot">

              <div class="ps-checkbox">

                <input class="form-control" type="checkbox" id="remember-me" name="remember-me"
                  [(ngModel)]="rememberMe">

                <label for="remember-me">Recordarme</label>

              </div>

              <a [routerLink]="['/forgot-password/']">Olvidó su contraseña?</a>

            </div>

            <div class="form-group submtit">

              <button class="ps-btn ps-btn--fullwidth">Iniciar Sesión</button>

            </div>

          </div>

          <!-- <div class="ps-form__footer">

            <p>Connect with:</p>

            <ul class="ps-list--social">

              <li><a class="facebook" href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a class="google" href="#"><i class="fab fa-google"></i></a></li>

            </ul>

          </div> -->
          <br>

        </div><!-- End Login Form -->

      </div>

    </form>

  </div>
