<!--=====================================
  Login - Register Content
  ======================================-->

<div class="ps-my-account">

  <div class="container">

    <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)" class="ps-form--account ps-tab-root needs-validation"
      novalidate>

      <ul class="ps-tab-list">

        <li class="active"><a>Recuperar mi Contraseña</a></li>

        <!-- <li class=""><a href="#register">Register</a></li> -->

      </ul>

      <div class="ps-tabs">

        <!--=====================================
                      Login Form
                      ======================================-->

        <div class="ps-tab active" id="sign-in">

          <div class="ps-form__content">

            <h5>Ingrese nueva Contraseña</h5>

            <div class="form-group">

              <input class="form-control" type="password" placeholder="Ingrese Contraseña" [(ngModel)]="pass"
                name="password" required minlength="4" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}"
                data-toggle="tooltip"
                title="Ingrese su nueva contraseña, debe contenet minimo 4 caracteres, mayusculas, minusculas y numeros"
                (change)="validatePassword(password)" #password>

              <div class="valid-feedback">Valido.</div>
              <div class="invalid-feedback">
                Porfavor
                llene este campo.
              </div>

            </div>

            <div class="form-group">

              <input class="form-control" type="password" placeholder="Confirmar Contraseña" [(ngModel)]="pass1"
                name="password1" required minlength="4" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}"
                data-toggle="tooltip"
                title="Ingrese su nueva contraseña, debe contenet minimo 4 caracteres, mayusculas, minusculas y numeros"
                (change)="validateSimil(password1)" #password1>

              <div class="valid-feedback">Valido.</div>
              <div class="invalid-feedback">
                Porfavor
                llene este campo.
              </div>
              <div class="invalid-feedback" *ngIf="pass != pass1">
                Las contraseñas no coincides
              </div>

            </div>

            <div class="form-group submtit">

              <button class="ps-btn ps-btn--fullwidth">Enviar</button>

            </div>

          </div>

          <!-- <div class="ps-form__footer">

                <p>Connect with:</p>

                <ul class="ps-list--social">

                  <li><a class="facebook" href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a class="google" href="#"><i class="fab fa-google"></i></a></li>

                </ul>

              </div> -->
          <br>

        </div><!-- End Login Form -->
      </div>

    </form>

  </div>

</div>
