import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product-breadcrumb',
  templateUrl: './product-breadcrumb.component.html',
  styleUrls: ['./product-breadcrumb.component.css'],
})
export class ProductBreadcrumbComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public token;
  public category;
  public line;
  public productName;
  public store;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
  }

  ngOnInit() {
    
    this.getAllData();
  }

  getAllData() {
    this._route.params.subscribe((params) => {
      let param = params['id'];
      this.getProducts(param);
    });
  }

  getProducts(id) {
    let object = {
      url: 'Product/GetProductById?id=' + id,
      token: this.token,
    };
    this._generalService.getObject(object).subscribe(
      (response) => {
        if (response) {
          this.category = response.category;
          this.line = response.line;
          this.productName = response.name;
        }
      },
      (error) => {}
    );
  }
}
