<!--=====================================
                Profile
======================================-->

<aside class="ps-block--store-banner">

  <div class="ps-block__user">

    <div class="ps-block__user-avatar">

      <img src="{{imgUrl}}" alt="">

      <div class="br-wrapper">

        <button class="btn btn-primary btn-lg rounded-circle" data-target="#newPicture" data-toggle="modal"><i
            class="fas fa-pencil-alt"></i></button>

      </div>



    </div>

    <div class="ps-block__user-content text-center text-lg-left">

      <h2 class="text-white">{{identity.fullName}}</h2>

      <p><i class="fas fa-user"></i> {{identity.userName.split('@')[0]}}</p>

      <p><i class="fas fa-envelope"></i> {{identity.userName}}</p>

      <button class="btn btn-warning btn-lg" data-target="#newPassword" data-toggle="modal">Cambia Contraseña</button>

    </div>

    <div class="row ml-lg-auto pt-5" *ngIf="!seller">

      <div class="col-lg-3 col-6">
        <div class="text-center">
          <a href="#">
            <h1><i class="fas fa-shopping-cart text-white"></i></h1>
            <h4 class="text-white">Orders <span class="badge badge-secondary rounded-circle">51</span></h4>
          </a>
        </div>
      </div><!-- box /-->

      <div class="col-lg-3 col-6">
        <div class="text-center">
          <a href="#">
            <h1><i class="fas fa-shopping-bag text-white"></i></h1>
            <h4 class="text-white">Products <span class="badge badge-secondary rounded-circle">51</span></h4>
          </a>
        </div>
      </div><!-- box /-->

      <div class="col-lg-3 col-6">
        <div class="text-center">
          <a href="#">
            <h1><i class="fas fa-bell text-white"></i></h1>
            <h4 class="text-white">Disputes <span class="badge badge-secondary rounded-circle">51</span></h4>
          </a>
        </div>
      </div><!-- box /-->

      <div class="col-lg-3 col-6">
        <div class="text-center">
          <a href="#">
            <h1><i class="fas fa-comments text-white"></i></h1>
            <h4 class="text-white">Messages <span class="badge badge-secondary rounded-circle">51</span></h4>
          </a>
        </div>
      </div><!-- box /-->
    </div>

  </div>

</aside><!-- s -->


<!--=====================================
                Nav Account
                ======================================-->

<div class="ps-section__content" id="myTab" role="tablist">

  <ul class="ps-section__links">
    <li class="nav-item {{tabIndex == 0 ?'active':''}}">
      <a class="nav-link" id="wish-tab" href="#wish" (click)="selectTab(0)" data-toggle="tab" role="tab"
        aria-controls="wish" aria-selected="true">Favoritos</a></li>
    <li class="nav-item {{tabIndex == 1 ?'active':''}}"><a (click)="selectTab(1)" class="nav-link" id="order-tab"
        href="#order" data-toggle="tab" role="tab" aria-controls="order" aria-selected="false">Mis Pedidos</a></li>
    <li class="nav-item {{tabIndex == 2 ?'active':''}}"><a (click)="selectTab(2)" class="nav-link" id="order-tab"
        href="#store" data-toggle="tab" role="tab" aria-controls="order" aria-selected="false">Tienda</a></li>
    <!-- <li><a href="my-account_new-store.html">My Store</a></li>
    <li><a href="my-account_my-sales.html">My Sales</a></li> -->
  </ul>

  <div class="tab-content" id="myTabContent">
    <div class="table-responsive {{tabIndex == 0 ? 'tab-pane fade show active':'tab-pane fade'}}" id="wish"
      role="tabpanel" aria-labelledby="wish-tab">
      <app-account-wishlist></app-account-wishlist>
    </div>

    <div class="table-responsive {{tabIndex == 1 ? 'tab-pane fade show active':'tab-pane fade'}}" id="order"
      role="tabpanel" aria-labelledby="order-tab">
      <app-account-shopping></app-account-shopping>
    </div>

    <div class="table-responsive {{tabIndex == 2 ? 'tab-pane fade show active':'tab-pane fade'}}" id="store"
      role="tabpanel" aria-labelledby="order-tab">
      <app-account-store></app-account-store>
    </div>


  </div>
</div>

<!--=====================================
MODAL PARA ESCRIBIR LA NUEVA CONTRASEÑA
======================================-->

<div class="modal" id="newPassword">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Cambio de contraseña</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">

        <form #loginForm="ngForm" (ngSubmit)="onSubmitPassword(loginForm)"
          class="ps-form--account ps-tab-root needs-validation" novalidate>

          <div class="ps-tabs">

            <!--=====================================
                      Login Form
                      ======================================-->

            <div class="ps-tab active" id="sign-in">

              <div class="ps-form__content">

                <h5>Ingrese nueva Contraseña</h5>

                <div class="form-group">

                  <input class="form-control" type="password" placeholder="Ingrese Contraseña" [(ngModel)]="pass"
                    name="password" required minlength="4" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}"
                    data-toggle="tooltip"
                    title="Ingrese su nueva contraseña, debe contenet minimo 4 caracteres, mayusculas, minusculas y numeros"
                    (change)="validatePassword(password)" #password>

                  <div class="valid-feedback">Valido.</div>
                  <div class="invalid-feedback">
                    Porfavor
                    llene este campo.
                  </div>

                </div>

                <div class="form-group">

                  <input class="form-control" type="password" placeholder="Confirmar Contraseña" [(ngModel)]="pass1"
                    name="password1" required minlength="4" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}"
                    data-toggle="tooltip"
                    title="Ingrese su nueva contraseña, debe contenet minimo 4 caracteres, mayusculas, minusculas y numeros"
                    (change)="validateSimil(password1)" #password1>

                  <div class="valid-feedback">Valido.</div>
                  <div class="invalid-feedback">
                    Porfavor
                    llene este campo.
                  </div>
                  <div class="invalid-feedback" *ngIf="pass != pass1">
                    Las contraseñas no coincides
                  </div>

                </div>

                <div class="form-group submtit">

                  <button class="ps-btn ps-btn--fullwidth">Enviar</button>

                </div>

              </div>


              <br>

            </div><!-- End Login Form -->
          </div>

        </form>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
      </div>

    </div>
  </div>
</div>



<!--=====================================
MODAL PARA CAMBIAR LA FOTO
======================================-->

<div class="modal" id="newPicture">

  <div class="modal-dialog">

    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Cambio Foto</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">

        <form class="ps-form--account ps-tab-root needs-validation" novalidate>

          <div class="custom-file">

            <input type="file" class="custom-file-input" id="customFile" accept=".jpg,.png,.jpeg"
              (change)="selectFile($event.target.files)">

            <label class="custom-file-label" for="customFile">Examinar...</label>

          </div>

          <figure class="text-center py-3">

            <img src="{{imgURL}}" class="img-fluid rounded-circle changePicture" style="width:150px">

          </figure>

          <div class="form-group submtit">

            <button (click)="onSubmitFoto()" type="button" class="ps-btn ps-btn--fullwidth">Cambiar</button>

          </div>

        </form>

      </div>

    </div>

  </div>

</div>
