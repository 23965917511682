<!--=====================================
	Header Promotion
	======================================-->
<section *ngIf="identity && !preload && topbanner != null">
  <div class="ps-block--promotion-header bg--cover"
    style="background: url(data:image/jpeg;base64,{{topbanner.tagImg}});">
    <div class="container">
      <div class="ps-block__left">
        <h3>{{topbanner.tagH3}}</h3>
        <figure>
          <p>{{topbanner.tagP1}}</p>
          <h4>{{topbanner.tagH4}}</h4>
        </figure>
      </div>
      <div class="ps-block__center">
        <p>{{topbanner.tagP2}}<span>{{topbanner.tagSpan}}</span></p>
      </div><a class="ps-btn ps-btn--sm" [routerLink]="['/product/',topbanner.productId]">{{topbanner.tagButton}}</a>
    </div>
  </div>
</section>

<section *ngIf="identity && preload" class="d-flex justify-content-center">
  <div class="spinner-border text-muted my-3"></div>
</section>
