<!--=====================================
  Login - Register Content
  ======================================-->

<div class="ps-my-account">

  <div class="container">

    <form #loginForm="ngForm" (ngSubmit)="onSubmit()" class="ps-form--account ps-tab-root" novalidate>

      <ul class="ps-tab-list">

        <li class="active"><a>Olvide mi Contraseña</a></li>

        <!-- <li class=""><a href="#register">Register</a></li> -->

      </ul>

      <div class="ps-tabs">

        <!--=====================================
                    Login Form
                    ======================================-->

        <div class="ps-tab active" id="sign-in">

          <div class="ps-form__content">

            <h5>Ingresar tu correo para recuperar</h5>

            <div class="form-group">

              <input class="form-control" type="text" placeholder="correo electronico" [(ngModel)]="username"
                name="email" email required>

              <div class="valid-feedback">Valid.</div>
              <div *ngIf="loginForm.submitted && loginForm.controls['email'].errors" class="invalid-feedback">Porfavor
                llene este campo.
              </div>

            </div>

            <div class="form-group submtit">

              <button class="ps-btn ps-btn--fullwidth">Enviar</button>

            </div>

          </div>

          <!-- <div class="ps-form__footer">

              <p>Connect with:</p>

              <ul class="ps-list--social">

                <li><a class="facebook" href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a class="google" href="#"><i class="fab fa-google"></i></a></li>

              </ul>

            </div> -->
          <br>

        </div><!-- End Login Form -->



      </div>

    </form>

  </div>

</div>
