import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';
import { WishListService } from '../../../services/wishList.service';
import { CartService } from '../../../services/cart.service';
import { IItem } from '../../../interface/item';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-search-showcase',
  templateUrl: './search-showcase.component.html',
  styleUrls: ['./search-showcase.component.css'],
})
export class SearchShowcaseComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public token;
  public totalFound;

  public preload: boolean = false;

  public products = [];

  public data;
  public page = 1;
  public pages;
  public itemPerPage = 12;
  public Search;
  public paginable;
  public sortValue;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location,
    private wishListService: WishListService,
    private cartService: CartService
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();

    this.data = {
      pagina: this.page,
      cantidadAMostrar: this.itemPerPage,
      paginable: true,
      isSearch: true,
      searchData: this.Search,
    };
  }

  ngOnInit() {
    this.identity = this._generalService.getIdentity();
    this.getAllData(this.data);
  }

  getAllData(data) {
    this._route.queryParamMap.subscribe((params) => {
      let seacrh = params.get('search');
      let page = params.get('page');
      data.searchData = seacrh;
      this.Search = seacrh;
      data.pagina = page;
      this.page = Number(page);
      this.getProducts(data);
    });
  }

  getProducts(data) {
    let object = {
      data: data,
      url: 'Product/AllProductsShowCase',
      token: this.token,
    };
    this._generalService.getObjectParams(object).subscribe(
      (response) => {
        console.log(response);
        if (response && response.data) {
          this.products = response.data;
          // response.data.forEach((item) => this.products.push(item));
          // console.log('yo lo', this.products);
          this.totalFound = response.totalData;
          this.pages = response.pages;
          this.itemPerPage = data.cantidadAMostrar;
        }
      },
      (error) => {}
    );
  }

  previousPage() {
    this.page--;
    this._router.navigate(['/products'], {
      queryParams: { search: this.Search, page: this.page },
    });
  }

  nextPage() {
    this.page++;
    this._router.navigate(['/products'], {
      queryParams: { search: this.Search, page: this.page },
    });
  }

  onScroll() {
    if (this.pages != this.page) {
      this.page++;
      let data = {
        pagina: this.page,
        cantidadAMostrar: this.itemPerPage,
        paginable: true,
        isSearch: true,
        searchData: this.Search,
      };
      this.getProducts(data);
    } else {
      console.log('No more lines. Finish page!');
    }
  }

  async addCart(data) {
    const { value: unit } = await Swal.fire({
      title: 'Ingrese cantidad',
      icon: 'info',
      input: 'range',
      inputAttributes: {
        min: data.quantityMin,
        max: '100',
        step: data.quantityStep,
      },
      inputValue: data.quantityMin,
    });
    if (unit) {
      const product: IItem = {
        id: data.id,
        name: data.name,
        img: data.imageUrl,
        price: data.price,
        quantity: Number(unit),
        description: data.presentationName,
        quantityMin: Number(data.quantityMin),
        quantityStep: Number(data.quantityStep),
      };

      this.cartService.changeCart(product);
    }
  }

  addwishList(data) {
    let object = {
      userId: this.identity.userId,
      productId: data,
    };
    this.wishListService.addWishList(object);
  }
}
