import { Component, OnInit } from '@angular/core';
import { ResetPassword } from '../../../models/resetPassword';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { NgxImageCompressService } from 'ngx-image-compress';

import Swal from 'sweetalert2';
import { ResourceLoader } from '@angular/compiler';
import { UserFoto } from '../../../models/userFoto';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.css'],
  providers: [GeneralService],
})
export class AccountProfileComponent implements OnInit {
  public imgUrl;
  public identity;
  public seller: boolean = false;
  public token;
  public userFoto: UserFoto;

  public username;
  public pass;
  public pass1;
  public expires;

  public imgURL;
  public tabIndex = 0;

  public newPassword: ResetPassword;

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private generalService: GeneralService,
    private imageCompress: NgxImageCompressService
  ) {
    this.token = this.generalService.getToken();
    this.newPassword = new ResetPassword('', '', '');
    this.userFoto = new UserFoto('', '');
  }

  ngOnInit() {
    this.identity = this.generalService.getIdentity();
    if (this.identity.userFoto) {
      this.imgUrl = this.identity.userFoto;
    } else {
      this.imgUrl =
        'https://ui-avatars.com/api/?size=512&background=' +
        localStorage.getItem('color') +
        '&color=fff&name=' +
        this.identity.fullName;
    }
    this.imgURL = this.imgUrl;
    if (this.identity.rolId != 2) {
      this.seller = true;
    }

    this.activeRouter.queryParamMap.subscribe((params) => {
      let page = params.get('page');
      if (page === 'order') {
        this.tabIndex = 1;
      }
      if (page === 'wish') {
        this.tabIndex = 0;
      }
    });
  }
  selectTab(id) {
    this.tabIndex = id;
  }

  onSubmitPassword(form) {
    if (form.invalid) {
      return;
    }

    this.newPassword.email = this.identity.userName;
    this.newPassword.newPassword = this.pass;

    let object = {
      data: this.newPassword,
      url: 'Usuarios/ResetPassword',
      token: this.token,
    };
    console.log(object);
    // loguear al usaurio y conseguir sus datos}
    this.generalService.postObject(object).subscribe(
      (response) => {
        console.log(response);
        if (!response) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: 'Algo salio mal..',
          });
        } else {
          Swal.fire({
            title: 'Felicidades',
            icon: 'success',
            text: 'Se contraseña fue cambiada',
          }).then((result) => {
            if (result) {
              setTimeout(function () {
                window.location.reload();
              }, 1);
            }
          });
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.error,
          }).then((result) => {
            if (result) {
              setTimeout(function () {
                window.location.reload();
              }, 1);
            }
          });
        }
      }
    );
  }

  validatePassword(password) {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/;
    if (!pattern.test(password.value)) {
      password.parentNode.classList.add('was-validated');

      password.value = '';
    }
  }

  validateSimil(password) {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/;
    if (!pattern.test(password.value) || this.pass !== password.value) {
      password.parentNode.classList.add('was-validated');
      // $(password).parent().addClass('was-validated')

      password.value = '';
    }
  }

  onSubmitFoto() {
    this.userFoto.email = this.identity.userName;
    this.userFoto.photo = this.imgURL;

    let object = {
      data: this.userFoto,
      url: 'Usuarios/UpdateFoto',
      token: this.token,
    };
    console.log(object);
    // loguear al usaurio y conseguir sus datos}
    this.generalService.putObject(object).subscribe(
      (response) => {
        console.log(response);
        if (!response) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: 'Algo salio mal..',
          });
        } else {
          Swal.fire({
            title: 'Felicidades',
            icon: 'success',
            text: 'Se imagen de perfil fue cambiada',
          }).then((result) => {
            if (result) {
              this.identity.userFoto = this.userFoto.photo;
              localStorage.setItem('identity', JSON.stringify(this.identity));
              setTimeout(function () {
                window.location.reload();
              }, 1);
            }
          });
        }
      },
      (error) => {
        const errorMessage = error as any;
        console.warn(error);
        if (errorMessage != null) {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: error.error,
          }).then((result) => {
            if (result) {
              setTimeout(function () {
                window.location.reload();
              }, 1);
            }
          });
        }
      }
    );
  }

  // comprees
  selectFile(files: FileList) {
    console.log(files);
    // validamos si hay un archivo
    if (files.length === 0) {
      return;
    }

    let fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    let objectImage = {
      data: formData,
      url: 'BucketManager',
      token: this.token,
    };
    this.generalService.uploadImage(objectImage).subscribe((event) => {
      Swal.fire('Felicidades!', 'Imagen subida con exito', 'success').then(
        (result) => {
          if (result.value) {
            this.imgURL = event;
          }
        }
      );
    });
  }
}
