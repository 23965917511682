import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GLOBAL } from '../../../services/global';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { CartService } from '../../../services/cart.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.css'],
})
export class HeaderMobileComponent implements OnInit {
  public title: string;
  public identity;
  public url: string;
  public menus;
  public token;
  public imgDefault;

  public carts = [];

  public totalItems = 0;
  public subTotalCart = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService,
    private _location: Location,
    private cartService: CartService
  ) {
    this.url = GLOBAL.url;
    this.token = this._generalService.getToken();
    this.identity = this._generalService.getIdentity();
  }

  ngOnInit() {
    this.menus = this._generalService.getMenu();
    this.imgDefault =
      'https://ui-avatars.com/api/?size=512&background=' +
      this.colorHEX() +
      '&color=fff&name=' +
      this.identity.fullName;
  }

  generarLetra() {
    var letras = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
    ];
    var numero = (Math.random() * 15).toFixed(0);
    return letras[numero];
  }

  colorHEX() {
    var coolor = '';
    for (var i = 0; i < 6; i++) {
      coolor = coolor + this.generarLetra();
    }
    return coolor;
  }

  ngDoCheck() {
    this.identity = this._generalService.getIdentity();
  }

  activateToggle() {
    $(document).on('click', '.sub-toggle', function () {
      $(this).parent().children('ul').toggle();
    });
  }

  filterSidebar(){    
    $('.ps-filter--sidebar').addClass('active');
    $('.ps-site-overlay').addClass('active');
  }

  closeFilterSidebar(){    
    $('.ps-filter--sidebar').removeClass('active');
    $('.ps-site-overlay').removeClass('active');
  }

  toggleSubMenu(event){    
    console.log(event.currentTarget.classList.toggle)
    event.currentTarget.classList.toggle('active');
  }

  selectMenu() {
    setTimeout(function () {
      window.location.reload();
    }, 1);
  }

  goSearch(value: string) {
    if (value.length === 0) {
      return;
    }
    this._router.navigate(['/search'], {
      queryParams: { search: value, page: 1 },
    });
  }
}
